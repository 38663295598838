<div class="card">
  <div class="row" style="margin-left: 0">
    <div class="col-md-6 fast_admin">
      <label style="font-weight: bold" class="label_heading"
        >Select an Environment to view rule engine status of form</label>
      <div class="selectdiv select_wrapinline" style="width: 100%">
        <label>
          <ng-select
            id="envList"
            class="custom-select"
            style="width: 100%"
            [items]="this.envList"
            [(ngModel)]="selectedEnv"
            (change)="onChangeEnv()"
          >
          </ng-select>
        </label>
      </div>
    </div>
    <div class="col-md-6 fast_admin" *ngIf="selectedEnv">
      <label style="font-weight: bold" class="label_heading"
        >Select a LOB to view</label>
      <div class="selectdiv select_wrapinline" style="width: 100%">
        <label>
          <ng-select
            id="ruleSetupList"
            class="custom-select"
            style="width: 100%"
            [items]="ruleSetups"
            bindLabel="Name"
            [(ngModel)]="selectedRuleSetup"
            (change)="onChangeRuleSetup($event)"
          >
          </ng-select>
        </label>
      </div>
    </div>
  </div>
  <div style="margin-left: 23px ;font-size: 14px;">
    <b *ngIf="formReady"
      >Effective Date: {{ this.effectiveDate | dateTimeUtcPipe }}</b
    >
    <b *ngIf="formReady" style="padding-left: 10px"
      >Expiration Date: {{ this.expirationDate | dateTimeUtcPipe }}</b
    >
    <b *ngIf="formReady" style="padding-left: 10px"
      >Submission Expiration Date:
      {{ this.submissionExpirationDate | dateTimeUtcPipe }}</b
    >
  </div>
  <div class="container" style="height:calc(100vh - 298px); overflow-y: auto">
  <mat-accordion multi>
    <ng-container *ngIf="formReady">
      <ng-container *ngFor="let rule of selectedForm.RuleSet.Rules; let i = index" style="overflow-y: visible;">
        <mat-expansion-panel [expanded]="true" style="margin-bottom: 2px; overflow: visible;"
          [ngClass]="{'attach-rule': rule.Action === 'Attach', 'remove-rule': rule.Action === 'Remove'}">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Rule {{ i + 1 }} [{{ rule.Action === 'Attach' ? 'Attach Rule' : 'Remove Rule' }}]
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-form-page-rule
            [currentRule]="rule"
            (deleteRule)="onDeleteRule($event)"
            (saveRule)="onSaveRule($event)"
          ></app-form-page-rule>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>
  
  </div>
  
  
  