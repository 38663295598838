//Angular Imports
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";

//Third Party Imports
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";

/**
 * Date Filter Component
 */
@Component({
  selector: "app-radio-filter",
  template: `
  <div class="ag-filter-body-wrapper">  
    <div class="ag-filter-body" style="padding: 4px;" *ngFor="let option of options"> 
      <label style="margin-bottom: 1px;">
        <input type="radio"
        [name]="_params.column.colId"
        [value]="option"
        [checked]="option === selectedValue"
        (click)="onRadioChange(option)" />
        {{ option }}
      </label>
    </div>
  </div>`,
  styles: ["span {margin:10px}"],
})
export class RadioFilterComponent
  implements IFilterAngularComp, OnInit, OnDestroy
{
  // Private Variables
  /**
   * Params
   */
  private _params: IFilterParams;

  /**
   * ValueGetter
   */
  private _valueGetter: (rowNode: RowNode) => any;

  // Public Variables
  /**
   * True or False  
   */
  public value: string;

  // Constructor
  /**
   * @ignore
   * */
  constructor(private cd : ChangeDetectorRef) {}

  // Angular Lifecycles
  /**
   * @ignore
   * */
  ngOnInit(): void {

  }

  /**
   * @ignore
   * */
  ngOnDestroy(): void {}

  // Public Methods
  /**
   * AG Init
   * @param params Grid filter params
   */
  public options: string[] =  [];
  public selectedValue: string;
  public valueMapping = {
    'Magic': '20',
    'MOL': '38',
  };

  agInit(params: IFilterParams): void {
    this._params = params;
    this._valueGetter = params.valueGetter.apply;
    this.options = params['values'];
  }

  /**
   * Is Filter Active (Method called by the grid to check if the filter is active)
   * */
  isFilterActive(): boolean {
    return this.selectedValue !== null && this.selectedValue !== undefined;
  }

  /**
   * Does Filter Pass (Method called by the grid to check if the data passes the filter)
   * @param params Grid Params
   */
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let {field} = this._params.colDef;
    return params.data[field] === this.selectedValue || params.data[field] === this.valueMapping[this.selectedValue];
    //return true;
  }

  /**
   * Get Model
   * */
  getModel(): any {
    return { value: this.selectedValue };
  }

  /**
   * Set Model
   * @param model Model
   */
  setModel(model: any): void {
    this.selectedValue = model ? model.value : null;
  }

 onRadioChange(value: string): void {
  if (this.selectedValue === value) {
    this.selectedValue = null;
  } else {
    this.selectedValue = value;
  }
    this._params.filterChangedCallback();
  }
}
