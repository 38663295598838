//Angular Imports 
import { Component, HostBinding, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, ViewChild, } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, EnvironmentService, AuthorizationService, CPMElement, CPMField
} from '../../../framework';
import { AdminForm, RuleSetup, Rule, ElementExpression, RuleSet } from '../../models';
//import { ExecutionService, TestingService } from '../../services';


import "rxjs";
import { saveAs } from "file-saver";
import { FilterConfig } from "../../models/filter-config.model";
import { filter } from "rxjs";
import { cloneDeep, forEach } from "lodash";
import { AnyNaptrRecord } from "dns";
import { FormControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';



/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-rule-setup',
  templateUrl: './form-page-rule-setup.component.html',
  styleUrls: ['./form-page-rule-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class FormPageRuleSetupComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() public currentForm: AdminForm;

  @Input() public lobList: string[];

  // Used to track the current form and determine if the actual form has changed in the OnChanges event
  private currentFormNumber: string;

  // Used to pass discard event up to page-container when Discard button is clicked
  @Output() discardChanges = new EventEmitter();

  @Output() saveForm = new EventEmitter();

  @Output() changeRuleSetup = new EventEmitter();
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;

  ruleSetupList: RuleSetup[];
  currentRuleSetup: RuleSetup;
  public filteredRules: Rule[] = []; // Declare filter
  public lobString: string;
  filters: any[] = [];

  //Logic for displaying LOB dates, dont display the div if rule setup has more than one LOB
  //public multipleLOBS: boolean = false;
  public effectiveDate: number = 0;
  public expirationDate: number = 0;
  public submissionExpirationDate: number = 0;

  //WC list for filter
  public writingCompanyList: any[] = [];
  public selectedWC: string = 'ZZ';
  public elementList: CPMElement[];
  public selectedElementName: string;
  public selectedElement: CPMElement;
  filteredFilters = this.filters;
  searchControl = new FormControl();

  public elementFilters: FilterConfig[] = [];
  public clearedFilters: boolean = true;
  recordsFound: boolean = true; 
  selectedFilters: any[] = [];// Default to true before filtering
  isAccordionOpen: boolean = false;



  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private authService: AuthorizationService,
    private sharedService: SharedService,
    private changeDetector: ChangeDetectorRef) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

ngOnInit(): void {
  this.sharedService.getAllCpmElements().subscribe((el) => {
      this.elementList = el;
      this.searchControl?.valueChanges.pipe(debounceTime(300)).subscribe(value => {
          this.filteredFilters = this.filters?.filter(filter =>
              filter.CPMDataElementName?.toLowerCase().includes(value?.toLowerCase())
          ) || [];
      });

      this.populateFilters();
      this.filterRecords();

      this.searchControl?.valueChanges.pipe(debounceTime(300)).subscribe((searchTerm) => {
          const lowerCaseTerm = searchTerm?.toLowerCase();
          this.filteredFilters = this.filters?.sort((a, b) => {
              if (a.CPMDataElementName?.toLowerCase().includes(lowerCaseTerm) &&
                  !b.CPMDataElementName?.toLowerCase().includes(lowerCaseTerm)) {
                  return -1;
              }
              if (!a.CPMDataElementName?.toLowerCase().includes(lowerCaseTerm) &&
                  b.CPMDataElementName?.toLowerCase().includes(lowerCaseTerm)) {
                  return 1;
              }
              return 0;
          }) || [];
      });

      this.changeDetector.detectChanges();
  });
}

  isDocumentPanelOpen = false;
  sharedDocuments = [
    {
      name: "Shared LOB Documentation",
      url:
        "https://markel.atlassian.net/wiki/spaces/ITFO/pages/33194254/FAST+Guidelines+for+Lob+s",
    },
  ];

 toggleDocumentPanel(event: Event): void {
    this.isDocumentPanelOpen = !this.isDocumentPanelOpen;
    event.stopPropagation();
  }

  
  closeDocumentPanelOutside(event: Event): void {
    if (!this.isDocumentPanelOpen) return;
    const clickedInside = (event.target as HTMLElement).closest(
      ".shared-document-info"
    );
    if (!clickedInside) {
      this.isDocumentPanelOpen = false;
    }
  }

  scrollToBottom(): void {
    if (this.scrollContainer?.nativeElement) {
      setTimeout(() => { 
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer?.nativeElement?.scrollHeight;
      });
    }
  }

  populateFilters(): void {
    const filterNames: string[] = this.elementList?.map(
      (element) => element.CPMDataElementName
    );
    filterNames?.forEach((name) => {
      let filterObj = {
        CPMDataElementName: name,
        ElementValues: [],
        SelectedElementValue: null,
        isOpen: false,
      };
      this.filters?.push(filterObj);
    });

    filterNames.forEach((filterName) => {
      const filter = this.filters.find(
        (f) => f.CPMDataElementName === filterName
      );
      if (filter) {
        const element = this.elementList.find(
          (e) => e.CPMDataElementName === filterName
        );
        if (element) {
          filter.ElementValues = element.CPMFieldValues || [];
          filter.isVisible = filter.ElementValues.length > 0;
          filter.ElementValues.forEach((value) => {
            value.e2Description = `${value.Code} - ${value.Description}`;
          });
        }
      }
      console.log(`Filter ${filterName}:`, filter.ElementValues);
    });
  }


isSelected(item, filter): boolean {
  return filter?.SelectedElementValue?.includes(item.Code) ?? false;
}

resetFilters(): void {
  this.elementFilters.forEach(filter => {
      filter.SelectedElementValue = null;
  });

  this.selectedFilters = []; 
  this.filteredRules = this.currentRuleSetup.RuleSet.Rules; 
  this.recordsFound = true; 
  this.filterRecords();
}

  toggleFilter(filter): void {
    filter.isOpen = !filter.isOpen;
  }
 
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    this.effectiveDate = 0;
    this.expirationDate = 0;
    this.submissionExpirationDate = 0;
    this.setRuleSetupNames(this.currentForm.RuleSetups);
    this.ruleSetupList = this.currentForm.RuleSetups;
    if (this.currentForm.InternalFormNumber !== this.currentFormNumber) {
      this.currentFormNumber = this.currentForm.InternalFormNumber;     
      var blankRuleSetup = new RuleSetup();
      blankRuleSetup.Name = "";
      blankRuleSetup.LOB = [];
      blankRuleSetup.IsDefault = false;
      blankRuleSetup.RuleSet = new RuleSet();
      this.currentRuleSetup = blankRuleSetup;
    const defaultRuleSetup = this.currentForm.RuleSetups.find(
      (setup) => setup.IsDefault
    );
    if (defaultRuleSetup) {
      this.currentRuleSetup = defaultRuleSetup;
      if (this.currentRuleSetup.LOB.length == 1) {
        var lobEff = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].EffectiveDate
        var lobExp = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].ExpirationDate;
        var lobSubExp = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].SubmissionExpirationDate;
        if (lobEff != null) {
          this.effectiveDate = lobEff;
        }
        if (lobExp != null) {
          this.expirationDate = lobExp;
        }
        if (lobSubExp != null) {
          this.submissionExpirationDate = lobSubExp;
        }
      }
    }
    }
  }

  toggleAccordion(): void {
    this.isAccordionOpen = !this.isAccordionOpen;
    if (!this.recordsFound) {
      this.isAccordionOpen = false; 
    }
  }

  setRuleSetupNames(setups: RuleSetup[]): void {
    for (let setup of setups) {
      var name = "";
      if (setup.LOB.length == 1) {
        name = this.lobList.find(item => item.startsWith(setup.LOB[0] + ' - '));
      }
      else {
        name = setup.LOB.join(' & ');
      }
      if (setup.IsDefault) {
        setup.Name = "Default: " + name;
      } else {
        setup.Name = name;
      }   
    }
  }

  isFilterPanelOpen = false;
  toggleFilterPanel(event: Event): void {
    event.stopPropagation(); 
    this.isFilterPanelOpen = !this.isFilterPanelOpen;
  }

  onElementChanged(event): void {
    let newFilter = new FilterConfig();
    let selectedCpmElement = this.elementList.filter(a => a.CPMDataElementName == event.CPMDataElementName)[0];
    newFilter.ElementValues = selectedCpmElement.CPMFieldValues;
    newFilter.ElementName = selectedCpmElement.CPMDataElementName;
    //Add new cpm field here for blank?
    let blankFieldValue = new CPMField();
    blankFieldValue.Code = "None"
    blankFieldValue.Description = "Rule does not have this element";
    newFilter.ElementValues.push(blankFieldValue);

    for (var i = 0; i < newFilter.ElementValues.length; i++) {
      newFilter.ElementValues[i].e2Description = newFilter.ElementValues[i].Code + ' - ' + newFilter.ElementValues[i].Description;
    }

    this.elementFilters.push(newFilter)
    this.changeDetector.detectChanges();

  }

  onFilterElementChange(filter: any): void {
    if (!filter || !filter.SelectedElementValue) {
        return;
    }
    const selectedValues = filter.SelectedElementValue || [];
    this.selectedFilters = this.selectedFilters.filter(f => f.CPMDataElementName !== filter.CPMDataElementName);

    if (selectedValues.length) {
        this.selectedFilters.push({ ...filter, SelectedElementValue: selectedValues });
    } else {
        filter.SelectedElementValue = [];
    }
    this.clearedFilters = false;
    this.filterRecords();
}

allPanelsExpanded = true;

toggleAllPanels() {
  this.allPanelsExpanded = !this.allPanelsExpanded;
 
 
}
  removeFilter(filter) {
    const index = this.elementFilters.indexOf(filter);
    if (index > -1) {
      this.elementFilters.splice(index, 1);
    }
    // You might also want to handle additional logic like updating other states or making API calls here.
  }

  onChangeRuleSetup(event: RuleSetup): void {
    this.selectedWC = 'ZZ';
    this.currentRuleSetup = event;
    this.effectiveDate = 0;
    this.expirationDate = 0;
    this.submissionExpirationDate = 0;
    if (this.currentRuleSetup.LOB.length < 2) {
      var lobEff = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].EffectiveDate
      var lobExp = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].ExpirationDate;
      var lobSubExp = this.currentForm.AttributeSets.filter(item => this.currentRuleSetup.LOB.indexOf(item.Scope) != -1)[0].SubmissionExpirationDate;
      if (lobEff != null) {
        this.effectiveDate = lobEff;
      }
      if (lobExp != null) {
        this.expirationDate = lobExp;
      }
      if (lobSubExp != null) {
        this.submissionExpirationDate = lobSubExp;
      } 
    }
    this.changeRuleSetup.emit(this.currentRuleSetup);
    if (this.currentRuleSetup.RuleSet.Rules.length > 0) {
      this.recordsFound = true;
    }
    
  }

  onChangeWritingCompany(event: string): void {

  }
    onCheckboxChange(item, filter, event) {
    if (event.checked) {
      if (!filter.SelectedElementValue) {
        filter.SelectedElementValue = [];
      }
      filter.SelectedElementValue.push(item.Code);
    } else {
      filter.SelectedElementValue = filter.SelectedElementValue.filter(value => value !== item.Code);
    }
    this.onFilterElementChange(filter);
  }
  
  clearAll() {
    this.selectedFilters = [];
    this.clearedFilters = true;
    this.filters.forEach((filter) => {
        filter.SelectedElementValue = null; 
    });
    this.filteredRules = this.currentRuleSetup.RuleSet.Rules; 
    this.recordsFound = this.filteredRules?.length > 0; 
    this.isFilterPanelOpen = true;
    this.changeDetector.markForCheck(); 
}

 
  filterRecords() {
    this.filteredRules = this.currentRuleSetup?.RuleSet?.Rules?.filter(rule => {
        return this.ruleFilterPasses(rule);
    });

    this.recordsFound = this.filteredRules?.length > 0 ;  
} 


ruleFilterPasses(rule: Rule): boolean {
  for (const filter of this.filters) {
    if (
      filter.SelectedElementValue &&
      filter.SelectedElementValue.length > 0
    )
     {
      const selectedValues = filter.SelectedElementValue;
      const matchingExpression = rule.Expressions.find(
        (ex) => ex.ElementName === filter.CPMDataElementName
      );
      if (
        !matchingExpression ||
        !selectedValues.some(
          (value) =>
            matchingExpression.IncludedList.includes(value) ||
            matchingExpression.MandatoryList.includes(value)
        )
      ) {
        return false;
      }
    }
  }
  return true;
}



  onDeleteRule(event: Rule): void {
    let index = this.currentRuleSetup.RuleSet.Rules.indexOf(event);
    if (index >= 0) {
      this.currentRuleSetup.RuleSet.Rules.splice(index, 1);
    }
    if(this.currentRuleSetup?.RuleSet?.Rules?.length === 0) {
      this.recordsFound = false;
    }

  }

  onCloneRule(event: Rule): void {
    let index = this.currentRuleSetup.RuleSet.Rules.indexOf(event);
    if (index >= 0) {
      let rule = new Rule();
      rule = _.cloneDeep(this.currentRuleSetup.RuleSet.Rules[index]);
      this.currentRuleSetup.RuleSet.Rules.push(rule);
    }
    this.scrollToBottom();
  }

  onSaveRule(event: Rule): void {
    this.currentRuleSetup.RuleSet.Rules[this.currentRuleSetup.RuleSet.Rules.indexOf(event)] = event;
    var index = _.findIndex(this.currentForm.RuleSetups, {Name: `${this.currentRuleSetup.Name}`});
    this.currentForm.RuleSetups[index] = this.currentRuleSetup;
  }

  onSaveRuleSetup(): void {
    // Fire event up to page-container to save the form
      //Set IsDefault variable based on default rule setup, shouldn't be neccessary after data is properly scripted
    for (var item of this.currentForm.RuleSetups) {
      if (item.Name.includes("Default") || item.IsDefault) {
        item.IsDefault = true;
      }
      else {
        item.IsDefault = false;
      }
    }

    this.saveForm.emit(this.currentForm);
  }

  //Remove rule setup from form
  deleteRuleSetup() {
    //Do not delete default
    if (!this.currentRuleSetup.IsDefault) {
      if(confirm("This will delete the current rule setup and add their LOBS to the default, continue?")){
      //Find default index and add LOBS that will be removed, then splice deleted setup
      var index = this.currentForm.RuleSetups.findIndex(x => x.Name === this.currentRuleSetup.Name);
      var defaultIndex = this.currentForm.RuleSetups.findIndex(x=> x.IsDefault);
      for (var i = 0; i < this.currentRuleSetup.LOB.length; i++) {
        this.currentForm.RuleSetups[defaultIndex].LOB.push(this.currentRuleSetup.LOB[i]);
      }
      this.currentForm.RuleSetups.splice(index, 1);
      this.onSaveRuleSetup();
      this.ngOnChanges();
      this.onChangeRuleSetup(this.currentForm.RuleSetups[defaultIndex]);
      }
    }
  }
  //When adding a new rule default setup for attach rule is 3 expressions, Writing Company, State, and Coverage
  //Remove rule default is just Coverage
  onAddRule(template: string): void {
    this.selectedWC = 'ZZ';
    let newRule: Rule = new Rule();
    newRule.Expressions = [];

    if (template === 'basic_remove') {     
      newRule.Action = "Remove";

      let expression: ElementExpression = new ElementExpression();
      expression.ElementName = "Coverage";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

    } else if (template === 'basic_attach') {
      newRule.Action = "Attach";
      
      let expression: ElementExpression = new ElementExpression();
      expression.ElementName = "Writing Company";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

      expression = new ElementExpression();
      expression.ElementName = "State";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

      expression = new ElementExpression();
      expression.ElementName = "Coverage";
      expression.Operator = "in";
      expression.IncludedList = [];
      expression.MandatoryList = [];
      newRule.Expressions.push(expression);

    }
    this.recordsFound = true;
    this.scrollToBottom();

    if(!this.currentRuleSetup.RuleSet) {
      this.currentRuleSetup.RuleSet = {} as RuleSet;
      this.currentRuleSetup.RuleSet.Rules = [];
    }
    this.currentRuleSetup.RuleSet.Rules.push(newRule);
  }
}
