<div class="modal-header">
  <h4 class="modal-title">Log Search</h4>
</div>

<div
  class="col-md-12"
  id="lob-content-container"
  style="padding-top: 20px; padding-bottom: 10px"
>
  <div class="heading-container fast_admin">
    <div class="col-md-12">
      <label class="label_heading">Environment:</label>
      <div class="selectdiv select_wrapinline" style="width: 20%">
        <label>
          <ng-select
            id="env"
            class="custom-select"
            style="width: 100%"
            [items]="envList"
            bindLabel="Name"
            bindValue="Name"
            [(ngModel)]="env"
          ></ng-select>
        </label>
      </div>
    </div>
    <label class="label_heading mrglt10" id="logIdLabel" style="margin-top: 7px"
      >Deal Number:</label
    >
    <label>
      <input
        id="txtDealNumber"
        class="vex-input"
        placeholder="Deal Number"
        [(ngModel)]="dealNumber"
        style="width: 100%"
      />
    </label>
    <span style="padding-left: 20px">
      <button
        type="button"
        class="mat_btn_hov_act"
        mat-raised-button
        (click)="searchLog()"
      >
        Search
      </button>
    </span>
  </div>
</div>
<div
class="row heading-container fast_admin"
id="policy-form-heading-container"
>
<div class="col-md-12" style="display: inline; padding-bottom: 20px; margin: 0 auto; float: left;">
  <label class="label_heading" style="margin-left: 17px;">Select System:</label>

  <div class="selectdiv select_wrapinline">
    <ng-select
      [items]="callingSystems"
      [(ngModel)]="callingSystem"
      (change)="onSelectCallingSystem($event)"
      class="custom-select"
    >
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item }}">{{ item }}</div>
      </ng-template>
    </ng-select>
  </div>
</div>
</div>

<div id="summary-grid-container" class="col-12" style="display: grid">
  <ag-grid-angular
    class="ag-theme-balham fast_panel_table"
    [gridOptions]="resultsGridOptions"
    style="height: 400px"
  >
  </ag-grid-angular>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="mat_btn_hov_act mrgrgt10"
    mat-raised-button
    (click)="selectLog()"
  >
    Select
  </button>
  <button
    type="button"
    class="mat_btn_hov_act"
    mat-raised-button
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
