//Angular Imports
import {
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  SharedService,
  EnvironmentService,
  SelectableItem,
} from "../../../framework";
import { RuleSetup, AdminForm, RuleSet } from "../../models";
import { FormPageService } from "../../services";

import "rxjs";
import { saveAs } from "file-saver";

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';

/**
 * Forms Component
 */
@Component({
  selector: "app-form-page-add-rule-setup",
  templateUrl: "./form-page-add-rule-setup.component.html",
  styleUrls: ["./form-page-add-rule-setup.component.scss"],
})
export class FormPageAddRuleSetupComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  @Input() public currentForm: AdminForm;

  @Input() public Action: string;

  // Used to populate the Line of Business grid
  lobGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];

  constructor(
    public activeModal: NgbActiveModal,
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private formPageService: FormPageService
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.lobSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureLobGrid();
    this.createSelectableItemLists();
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  ngOnChanges(): void {}

  addRuleSetup(): void {
    let newSetup: RuleSetup = new RuleSetup();

    try {
      for (let item of this.lobSelectableItems) {
        if (item.Selected) {
          newSetup.LOB.push(item.Code);

          // Find the setup this LOB is currently in and remove it from there
          for (let currentSetup of this.currentForm.RuleSetups) {
            let index = currentSetup.LOB.indexOf(item.Code);
            if (index !== -1) {
              // LOB currently exists in this setup, remove it
              //Check first if rule setup already exists on its own, do nothing if it does
              if (currentSetup.LOB.length == 1) {
                this.toastr.error("Rule setup already exists", "ERROR");
                return;
              }
              else {
                currentSetup.LOB.splice(index, 1);
              }

            }
          }
        }
      }
      let set: RuleSet = new RuleSet();
      set = this.currentForm.RuleSetups.filter((x) => x.IsDefault)[0].RuleSet;
      newSetup.RuleSet = set;
      this.currentForm.RuleSetups.push(newSetup);
      this.setRuleSetupNames(this.currentForm.RuleSetups);
      this.activeModal.close(this.currentForm);
    } catch (e) {
      this.toastr.error(
        "Error occurred while adding new RuleSetup.",
        "Form Page"
      );
    }
  }

  setRuleSetupNames(setups: RuleSetup[]): void {
    for (let setup of setups) {
      if (setup.IsDefault) {
        setup.Name = "Default - " + setup.LOB.join(" - ");
      } else {
        setup.Name = setup.LOB.join(" - ");
      }
    }
  }

  private createSelectableItemLists(): void {
    // Load LOBs
    if (this.currentForm) {
      this.formPageService.getLobSelectableItemList().subscribe(
        (res) => {
          // Need to clone this because service is returning a cached object and we don't want to modify it
          let items: SelectableItem[] = _.cloneDeep(res);

          // foreach lob on the form, add it to the lobSelectableItems list, if we are just viewing codes add all LOBs to list
          if (this.Action == "View") {
            this.lobSelectableItems = items;
          } else {
            for (var lob of this.currentForm.LOB) {
              var item = items.find((x) => x.Code === lob);
              this.lobSelectableItems.push(item);
            }
          }

          // this.lobSelectableItems = items;
          this.sortSelectableItems(this.lobSelectableItems);

          if (this.lobGridOptions.api) {
            this.lobGridOptions.api.setRowData(this.lobSelectableItems);
            this.lobGridOptions.columnApi.autoSizeAllColumns();
          }
        },
        (error) => {
          this.toastr.error(
            "Error occurred while fetching LOB list.",
            "Form Page"
          );
        }
      );
    }
  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        if (a.VisibleDescription < b.VisibleDescription) {
          return -1;
        } else {
          return 1;
        }
      } else if (a.Selected) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  private configureLobGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.lobGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createLobColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.lobGridOptions.api.setRowData(this.lobSelectableItems);
        this.lobGridOptions.api.sizeColumnsToFit();
        this.lobGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.lobGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * configure forms grid columns
   */
  private createLobColumnDef(): any[] {
    return [
      {
        headerName: "",
        field: "Selected",
        maxWidth: 40,
        suppressFilter: true,
        cellRenderer: (params) => {
          return `<input type='checkbox' ${
            params.value == true ? "checked" : ""
          } />`;
        },
      },
      {
        headerName: "",
        field: "VisibleDescription",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
    ];
  }

  private onRowSelected(event: any): void {
    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
    }
  }
}
