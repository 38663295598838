//Angular Imports 
import { Component, HostBinding, Input, Output, EventEmitter } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, EnvironmentService
} from '../../../framework';
import { CPMElement, CPMField } from "../../../framework/models";



import 'rxjs';
import { saveAs } from 'file-saver';
import { ReportExpression } from '../../models/reportExpressionModel';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../form-page/components/confirmation-dialog/confirmation-dialog.component'


//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';

declare var Blockly: any;
//import * as Blockly from 'blockly';

/**
 * Forms Component
*/
@Component({
  selector: 'app-report-expression',
  templateUrl: './report-expression.component.html',
  styleUrls: ['./report-expression.component.scss']
})

export class ReportExpressionComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() public currentExpression: ReportExpression;

  @Output() deleteExpression = new EventEmitter<ReportExpression>();

  @Output() saveExpression = new EventEmitter<ReportExpression>();

  public elementList: CPMElement[];
  public selectedElementName: string;
  public selectedElement: CPMElement;

  public operatorList = [{ Name: "in" },  { Name: "=" }, { Name: ">" }, { Name: ">=" }, { Name: "<" }, { Name: "<=" }, {Name: "<>"}, {Name: "CountEqual"},{Name: "CountNotEqual"}, {Name: "all except"}];
  public selectedOperator: string;

  public valueList: any[];
  public includedList: any[];
  public mandatoryList: any[];
  public includedDescriptions: string[];
  public mandatoryDescriptions: string[];
  public isNegativeCondition: boolean = false;
  showList: { included: boolean, mandatory: boolean } = { included: false, mandatory: false }; 
  //public selectedValueList any[];


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService, private dialog: MatDialog,private sharedService: SharedService) {
    super(_appInsightsService);
    this.width = "100%";

    //this.includedList = [];
    //this.excludedList = [];
  }

//   ngOnInit(): void {

//     this.sharedService.getAllCpmElements().subscribe(el => {
//       this.elementList = el;

      
//       this.onSelectElement();
//     });


//    // if (this.currentExpression.IncludedList.find(x => x === "ZZ")) {
//       //this.selectedOperator = "all except";
//     //} else {
//       this.selectedOperator = this.currentExpression.Operator;
//     //}

//     this.includedList = _.cloneDeep(this.currentExpression.IncludedList);
//     this.mandatoryList = _.cloneDeep(this.currentExpression.MandatoryList);
//   }

//   ngAfterViewInit(): void {
    
//   }

//   ngOnDestroy(): void {

//   }

//   ngOnChanges(): void {
   
//   }

//   populateElementValueList(): void {
//     //this.sharedService.getMasterDataFromCpmElement(this.selectedElement).subscribe(el => {
//     //  this.valueList = [];
//     //  for (var i = 0; i < el.CPMElementList.length; i++) {
//     //    this.valueList.push({ Name: el.CPMElementList[i].Name + ' - ' + el.CPMElementList[i].Description, Code: el.CPMElementList[i].Name})
//     //  }
//     //});
//   }

//   onSelectElement(elementName:string = ""): void {

//     if(elementName == ""){
//         if (this.currentExpression.ElementName === "WritingCompany") {
//           elementName = "Writing Company";
//         } else {
//           elementName = this.currentExpression.ElementName;
//         }
//       }

//     this.selectedElement = this.elementList.find(x => x.CPMDataElementName === elementName);
//     this.selectedElementName = this.selectedElement.CPMDataElementName;


//     this.valueList = [];
//     if(this.selectedElement.CPMFieldValues != null){
//       for (var i = 0; i < this.selectedElement.CPMFieldValues.length; i++) {
//         let cpmField: CPMField = this.selectedElement.CPMFieldValues[i];
//         this.valueList.push({ Name: cpmField.Code + ' - ' + cpmField.Description, Code: cpmField.Code })
//       }
//     }
//   }

//   onElementChanged(event): void {
//     this.onSelectElement(event.CPMDataElementName);

//     this.includedList = [];
//     this.mandatoryList = [];
//     this.currentExpression.IncludedList = [];
//     this.currentExpression.MandatoryList = [];
//     this.currentExpression.IncludedDescriptions = [];
//     this.currentExpression.MandatoryDescriptions = [];
//     this.currentExpression.ElementName = this.selectedElementName;
//     this.saveExpression.emit(this.currentExpression);
//   }

//   onIncludeValues(event): void {
//     this.currentExpression.IncludedDescriptions = [];
//     this.currentExpression.IncludedList = this.includedList;
//     for (var i of event) {
//       this.currentExpression.IncludedDescriptions.push(i.Name);
//     }
//     this.saveExpression.emit(this.currentExpression);
//   }

//   onMandatoryValues(event): void {
//    this.currentExpression.MandatoryDescriptions = [];
//    this.currentExpression.MandatoryList = this.mandatoryList;
//     for (var i of event) {
//       this.currentExpression.MandatoryDescriptions.push(i.Name);
//     }
//    this.saveExpression.emit(this.currentExpression);
//   }

//   onSelectOperator(event): void {
//     if (['in', '=', '<>', 'all except'].indexOf(this.currentExpression.Operator) != -1 && [">", ">=", "<", "<=", "CountEqual", "CountNotEqual"].indexOf(this.selectedOperator) != -1) {
//       console.log('hmm');
//       this.includedList = [];
//       this.mandatoryList = [];
//       this.currentExpression.IncludedList = [];
//       this.currentExpression.MandatoryList = [];
//       this.currentExpression.IncludedDescriptions = [];
//       this.currentExpression.MandatoryDescriptions = [];
//     }
//     else if (['in', '=', '<>', 'all except'].indexOf(this.selectedOperator) != -1 && [">", ">=", "<", "<=", "CountEqual", "CountNotEqual"].indexOf(this.currentExpression.Operator) != -1) {
//       this.includedList = [];
//       this.mandatoryList = [];
//       this.currentExpression.IncludedList = [];
//       this.currentExpression.MandatoryList = [];
//       this.currentExpression.IncludedDescriptions = [];
//       this.currentExpression.MandatoryDescriptions = [];
//     }
//     this.currentExpression.Operator = this.selectedOperator;
//     this.saveExpression.emit(this.currentExpression);
//   }

//   onDeleteExpression(): void {
//     if(confirm("This will delete the condition, continue?")){
//       this.deleteExpression.emit(this.currentExpression);
//     }
//   }

// }

ngOnInit(): void {
  this.sharedService.getAllCpmElements().subscribe(el => {
    this.elementList = el;
    this.onSelectElement();
  });


 // if (this.currentExpression.IncludedList.find(x => x === "ZZ")) {
    //this.selectedOperator = "all except";
  //} else {
    this.selectedOperator = this.currentExpression.Operator;
  //}

  this.includedList = _.cloneDeep(this.currentExpression.IncludedList);
  this.mandatoryList = _.cloneDeep(this.currentExpression.MandatoryList);
}

ngAfterViewInit(): void {
  
}

handleUpdatedList(updatedList: string[], type: string) {
  if (type === 'included') {
      this.includedList = updatedList;
      this.currentExpression.IncludedList = this.includedList; 
  } else if (type === 'mandatory') {
      this.mandatoryList = updatedList;
      this.currentExpression.MandatoryList = this.mandatoryList; 
  }
  this.saveExpression.emit(this.currentExpression);
}


toggleList(type: string) {
if (type === 'included') {
  this.showList.mandatory = false; 
  this.showList.included = !this.showList.included; 
} else if (type === 'mandatory') {
  this.showList.included = false; 
  this.showList.mandatory = !this.showList.mandatory; 
}
}

ngOnDestroy(): void {

}

ngOnChanges(): void {
 
}

populateElementValueList(): void {
  //this.sharedService.getMasterDataFromCpmElement(this.selectedElement).subscribe(el => {
  //  this.valueList = [];
  //  for (var i = 0; i < el.CPMElementList.length; i++) {
  //    this.valueList.push({ Name: el.CPMElementList[i].Name + ' - ' + el.CPMElementList[i].Description, Code: el.CPMElementList[i].Name})
  //  }
  //});
}

onSelectElement(elementName:string = ""): void {

  if(elementName == ""){
      if (this.currentExpression.ElementName === "WritingCompany") {
        elementName = "Writing Company";
      } else {
        elementName = this.currentExpression.ElementName;
      }
    }

  this.selectedElement = this.elementList.find(x => x.CPMDataElementName === elementName);
  this.selectedElementName = this.selectedElement.CPMDataElementName;


  this.valueList = [];
  if(this.selectedElement.CPMFieldValues != null){
    for (var i = 0; i < this.selectedElement.CPMFieldValues.length; i++) {
      let cpmField: CPMField = this.selectedElement.CPMFieldValues[i];
      this.valueList.push({ Name: cpmField.Code + ' - ' + cpmField.Description, Code: cpmField.Code })
    }
  }
}

onElementChanged(event): void {
  this.onSelectElement(event.CPMDataElementName);

  this.includedList = [];
  this.mandatoryList = [];
  this.currentExpression.IncludedList = [];
  this.currentExpression.MandatoryList = [];
  this.currentExpression.IncludedDescriptions = [];
  this.currentExpression.MandatoryDescriptions = [];
  this.currentExpression.ElementName = this.selectedElementName;
  this.saveExpression.emit(this.currentExpression);
}

onIncludeValues(event): void {
  this.currentExpression.IncludedDescriptions = [];
  this.currentExpression.IncludedList = this.includedList;
  for (var i of event) {
    this.currentExpression.IncludedDescriptions.push(i.Name);
  }
  this.saveExpression.emit(this.currentExpression);
}

onMandatoryValues(event): void {
  this.currentExpression.MandatoryDescriptions = [];
 this.currentExpression.MandatoryList = this.mandatoryList;
 for (var i of event) {
  this.currentExpression.MandatoryDescriptions.push(i.Name);
}
 this.saveExpression.emit(this.currentExpression);
}

onSelectOperator(event): void {
  if (['in', '=', '<>', 'all except'].indexOf(this.currentExpression.Operator) != -1 && [">", ">=", "<", "<=", "CountEqual", "CountNotEqual"].indexOf(this.selectedOperator) != -1) {
    console.log('hmm');
    this.includedList = [];
    this.mandatoryList = [];
    this.currentExpression.IncludedList = [];
    this.currentExpression.MandatoryList = [];
    this.currentExpression.IncludedDescriptions = [];
    this.currentExpression.MandatoryDescriptions = [];
  }
  else if (['in', '=', '<>', 'all except'].indexOf(this.selectedOperator) != -1 && [">", ">=", "<", "<=", "CountEqual", "CountNotEqual"].indexOf(this.currentExpression.Operator) != -1) {
    this.includedList = [];
    this.mandatoryList = [];
    this.currentExpression.IncludedList = [];
    this.currentExpression.MandatoryList = [];
    this.currentExpression.IncludedDescriptions = [];
    this.currentExpression.MandatoryDescriptions = [];
  }
  this.currentExpression.Operator = this.selectedOperator;
  this.saveExpression.emit(this.currentExpression);
}

onDeleteExpression(): void {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    width: '300px',
    data: { message: 'Delete this rule?' }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      
      this.deleteExpression.emit(this.currentExpression);
    }
  });
}

}

