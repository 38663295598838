import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-report-expression-detail-view',
  templateUrl: './report-expression-detail-view.component.html',
  styleUrls: ['./report-expression-detail-view.component.scss']
})
export class ReportExpressionDetailViewComponent implements OnInit {
  @Input() itemList: any[] = []; 
  @Output() updatedList = new EventEmitter<string[]>();
  @Input() valueList: any[] = [];
  @Input() showList: boolean = false;  
  public displayedNames: string[] = [];
  constructor() { }

  
  ngOnInit() {
    this.updateDisplayedNames();
  }

  ngOnChanges() {
    this.updateDisplayedNames();
  }

  removeValue(code: string) {
    console.log('Removing from itemList:', code);
    this.itemList = this.itemList?.filter(v => v !== code);
    this.updateDisplayedNames();
    this.updatedList.emit(this.itemList);
    console.log('Updated displayedNames:', this.displayedNames);
  }

  updateDisplayedNames() {
    this.displayedNames = this.itemList?.map(code => {
      const item = this.valueList?.find(value => value?.Code === code);
      if (item) {
        const namePart = item.Name?.split(' - ')[1]; 
        return `${code}-${namePart}`; 
      }
      return `${code}-`; 
    }).filter(name => name !== `{code}-`);
  }

  onClose() {
    this.showList = false;
  }
}


