<!-- <div>
  <div class="row expression_row rounded-lg">
    <div
      class="select_default expression_element"
      style="display: inline-block"
    >
      <label>
        <ng-select
          id="elementList"
          style="min-width: 200px"
          [items]="elementList"
          bindLabel="CPMDataElementName"
          bindValue="CPMDataElementName"
          [(ngModel)]="selectedElementName"
          (change)="onElementChanged($event)"
          [clearable]="true"
          placeholder="Select element"
        ></ng-select>
      </label>
    </div>

    <div
      class="select_default expression_element"
      style="display: inline-block"
    >
      <label>
        <ng-select
          id="operatorList"
          style="min-width: 100px"
          [items]="operatorList"
          bindLabel="Name"
          bindValue="Name"
          [(ngModel)]="selectedOperator"
          (change)="onSelectOperator($event)"
          [clearable]="true"
        ></ng-select>
      </label>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === 'in' ||
        selectedOperator === '=' ||
        selectedOperator === '<>' ||
        selectedOperator === 'all except'
      "
      style="display: inline-block; width: auto"
    >
      <label>
        <ng-select
          id="includedList"
          [items]="valueList"
          bindLabel="Name"
          bindValue="Code"
          [(ngModel)]="includedList"
          (change)="onIncludeValues($event)"
          [clearable]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          placeholder="Values"
        ></ng-select>
      </label>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === 'in' ||
        selectedOperator === '=' ||
        selectedOperator === '<>' ||
        selectedOperator === 'all except'
      "
      style="display: inline-block; max-width: 400px"
    >
      <div>
        <label>
          <ng-select
            id="mandatoryList"
            [items]="valueList"
            bindLabel="Name"
            bindValue="Code"
            [(ngModel)]="mandatoryList"
            (change)="onMandatoryValues($event)"
            [clearable]="true"
            [multiple]="true"
            [closeOnSelect]="false"
            placeholder="Mandatory Values"
          ></ng-select>
        </label>
      </div>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === '>' ||
        selectedOperator === '<' ||
        selectedOperator === '>=' ||
        selectedOperator === '<=' ||
        selectedOperator === 'CountEqual' ||
        selectedOperator === 'CountNotEqual'
      "
      style="display: inline-block; width: auto; align-self: center"
    >
      <label>
        <input
          [(ngModel)]="includedList[0]"
          (change)="onIncludeValues($event)"
          placeholder="Enter Value"
          style="border: 1px solid lightgray; border-radius: 5px; height: 35px"
        />
      </label>
    </div>

    <div
      class="select_default expression_element"
      *ngIf="
        selectedOperator === '>' ||
        selectedOperator === '<' ||
        selectedOperator === '>=' ||
        selectedOperator === '<=' ||
        selectedOperator === 'CountEqual' ||
        selectedOperator === 'CountNotEqual'
      "
      style="display: inline-block; width: auto; align-self: center"
    >
      <label>
        <input
          [(ngModel)]="mandatoryList[0]"
          (change)="onMandatoryValues($event)"
          placeholder="Enter Mandatory Value"
          style="border: 1px solid lightgray; border-radius: 5px; height: 35px"
        />
      </label>
    </div> -->

    <!-- Delete button -->
    <!-- <div
      class="expression_element"
      style="
        display: inline-block;
        padding: 4px 5px 4px 5px;
        float: right;
        margin-top: 9px;
      "
    >
      <button
        type="button"
        mat-raised-button
        class="btn-teal"
        (click)="onDeleteExpression()"
      >
        Delete
      </button>
    </div>
  </div> -->

  <!--
    <div class="row">
      <div class="col-md-3 select_ant">
        <label>
          <ng-select id="elementList"
                     [items]="elementList"
                     bindLabel="CPMDataElementName"
                     bindValue="CPMDataElementName"
                     [(ngModel)]="selectedElement"
                     (change)="onSelectElement($event)"
                     [clearable]="true"
                     placeholder="Select element"></ng-select>
        </label>
      </div>

      <div class="col-md-1 select_ant">
        <label>
          <ng-select id="operatorList"
                     [items]="operatorList"
                     bindLabel="Name"
                     bindValue="Name"
                     [(ngModel)]="selectedOperator"
                     (change)="onSelectOperator($event)"
                     [clearable]="true"></ng-select>
        </label>
      </div>

      <div class="col-md-4 select_ant">
        <label>
          <ng-select id="includedList"
                     [items]="valueList"
                     bindLabel="Name"
                     bindValue="Code"
                     [(ngModel)]="includedList"
                     (change)="onIncludeValues($event)"
                     [clearable]="true"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     placeholder="Included list"></ng-select>
        </label>
      </div>

      <div class="col-md-1" style="text-align: right">
        excl
      </div>

      <div class="col-md-3 select_ant">

        <div>
          <label>
            <ng-select id="excludedList"
                       [items]="valueList"
                       bindLabel="Name"
                       bindValue="Code"
                       [(ngModel)]="excludedList"
                       (change)="onExcludeValues($event)"
                       [clearable]="true"
                       [multiple]="true"
                       [closeOnSelect]="false"
                       placeholder="Excluded list"></ng-select>
          </label>
        </div>

      </div>

    </div>
  -->
<!-- </div> -->
<div>
  <div class="row expression_row rounded-lg">
    <!-- Element Selection (3 columns) -->
    <div class="col-md-3 select_default expression_element" >
      <label>
        <ng-select
          id="elementList"
          style="min-width: 250px;"
          [items]="elementList"
          bindLabel="CPMDataElementName"
          bindValue="CPMDataElementName"
          [(ngModel)]="selectedElementName"
          (change)="onElementChanged($event)"
          [clearable]="true"
          placeholder="Select element"
        ></ng-select>
      </label>
    </div>

    <!-- Operator Selection (2 columns) -->
    <div class="col-md-2 select_default expression_element">
      <label>
        <ng-select
          id="operatorList"
          [items]="operatorList"
          style="min-width: 150px"
          bindLabel="Name"
          bindValue="Name"
          [(ngModel)]="selectedOperator"
          (change)="onSelectOperator($event)"
          [clearable]="true"
        ></ng-select>
      </label>
    </div>

    <!-- Include List (3 columns) -->
    <div
      class="col-md-4 select_default expression_element"
      *ngIf="
      selectedOperator === 'in' ||
      selectedOperator === '=' ||
      selectedOperator === '<>' ||
      selectedOperator === 'all except'
    "
    
  >
    
      <label style="width: 94%;">
        <ng-select
          id="includedList"
          [items]="valueList"
          bindLabel="Name"
          bindValue="Code"
          style="width:100%;"
          [(ngModel)]="includedList"
          (change)="onIncludeValues($event)"
          [clearable]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          placeholder="Values"
        ></ng-select>
      </label>
      <button (click)="includedList?.length > 0 ? toggleList('included') : null"  style="border: none; background: transparent;">
        <i class="fas fa-ellipsis-v" [style.color]="includedList?.length > 0 ? 'var(--color-teal)' : 'gray'" style="color: var(--color-teal); font-size: 18px;"></i>
      </button>
        <div *ngIf="showList?.included">
        <app-report-expression-detail-view
         [valueList]="valueList"
          [itemList]="includedList"
          [showList]="showList.included"
          (updatedList)="handleUpdatedList($event, 'included')"
        ></app-report-expression-detail-view>
      </div>
    </div>

    <div
      class="col-md-3 select_default expression_element"
      *ngIf="
        selectedOperator === 'in' ||
        selectedOperator === '=' ||
        selectedOperator === '<>' ||
        selectedOperator === 'all except'
      "
          >
      <label style="width: 92%;">
        <ng-select
          id="mandatoryList"
          [items]="valueList"
          bindLabel="Name"
          bindValue="Code"
          [(ngModel)]="mandatoryList"
          style="width:100%;"
          (change)="onMandatoryValues($event)"
          [clearable]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          placeholder="Mandatory Values"
        ></ng-select>
      </label>
      <button (click)="mandatoryList?.length > 0 ? toggleList('mandatory') : null" style="border: none; background: transparent;">
        <i class="fas fa-ellipsis-v" [style.color]="mandatoryList?.length > 0 ? 'var(--color-teal)' : 'gray'" style=" font-size: 18px;"></i>
      </button>
        <div *ngIf="showList?.mandatory">
        <app-report-expression-detail-view
        [valueList]="valueList"
        [showList]="showList.mandatory"
        [itemList]="mandatoryList"
        (updatedList)="handleUpdatedList($event, 'mandatory')"
      ></app-report-expression-detail-view>
      </div>
  </div>

    <div
      class="col-md-3 select_default expression_element"
      *ngIf="
      selectedOperator === '>' ||
      selectedOperator === '<' ||
      selectedOperator === '>=' ||
      selectedOperator === '<=' ||
      selectedOperator === 'CountEqual' ||
      selectedOperator === 'CountNotEqual'
    "
    >
      <label>
        <input
          [(ngModel)]="includedList[0]"
          (change)="onIncludeValues($event)"  
          style="min-width: 233px; height:49px;overflow-y: auto;border: 1px solid lightgray;border-radius: 5px; height: 32px;margin-top: 8px;"
          placeholder="Enter Value"
         
        />
      </label>
    </div>

    <!-- Input for Mandatory Value (3 columns) -->
    <div
      class="col-md-3 select_default expression_element"
      *ngIf="
      selectedOperator === '>' ||
      selectedOperator === '<' ||
      selectedOperator === '>=' ||
      selectedOperator === '<=' ||
      selectedOperator === 'CountEqual' ||
      selectedOperator === 'CountNotEqual'
    "
    >
      <label>
        <input
          [(ngModel)]="mandatoryList[0]"
          (change)="onMandatoryValues($event)"
          placeholder="Enter Mandatory Value"
          style="min-width: 233px; height:49px;overflow-y: auto;border: 1px solid lightgray;border-radius: 5px; height: 32px;margin-top: 8px;"
         
        />
      </label>
    </div>

 <!-- Delete button -->
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end" style="margin-top: 2px;">

    <button
    type="button"
    mat-raised-button
    class="btn-teal"
    (click)="onDeleteExpression()"
    style="border: none; background: transparent"
  >
    <i class="fas fa-trash-alt"></i>
  </button>
  </div> 
  </div>
</div>
