//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
//Third Party Imports
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { MyDatePickerModule } from "mydatepicker";
//Internal Imports
import { TestHarnessComponent } from "./components/testHarness.component";
import { TestHarnessRoutingModule } from "./testHarness.routing";
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../../../framework";
import { UserNotesModule } from "../../../user-notes/user-notes.module";
import { TestHarnessService } from "./services/testHarness.service";
import { TestHarnessManageComponent } from "./components/testHarness-manageTests/testHarness-manageTests.component";
import { TestHarnessTestCaseComponent } from "./components/testHarness-testCase/testHarness-testCase.component";
import { TestHarnessTestGroupComponent } from "./components/testHarness-testGroup/testHarness-testGroup.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { LogsModule } from "../../../logs/logs.module";
import { TestCaseError } from "./components/testCaseError/testCaseError.component";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from '@angular/material/tooltip';

/**
  Forms Module
*/
@NgModule({
  declarations: [
    TestHarnessComponent,
    TestHarnessManageComponent,
    TestHarnessTestCaseComponent,
    TestHarnessTestGroupComponent,
    TestCaseError,
  ],
  imports: [
    MyDatePickerModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TestHarnessRoutingModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    MatTabsModule,
    MatButtonToggleModule,
    LogsModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent]),
  ],
  providers: [TestHarnessService],
})
export class TestHarnessModule {}
