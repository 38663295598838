//Angular Imports
import {
  Component,
  HostBinding,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
//Third Party Imports
import { GridOptions, IDatasource, IGetRowsParams, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports

import "rxjs";
import {
  AppInsightsService,
  EnvironmentService,
  DateTimeUtcPipe,
  SharedService,
  ApplicationInsightsBaseComponent,
  CPMElement,
  DateFilterComponent,
  BooleanFilterComponent,
  RadioFilterComponent,
  CheckboxFilterComponent,
  DatePickerFilterComponent
} from "../../../framework";
import { EXECUTION_ENGINE_LIST } from "../../../report/configs";
import { QueueService } from "../services/queue.service";
import { QueueLog } from "../models/queueLog";
import { QueueError } from "./queueError/queueError.component";
import { DateTime } from "luxon";
import { QueueDetailComponent } from "./queueDetails/queueDetails.component";
import { saveAs } from "file-saver";

export interface QueueFilter {
  name: string;
  selected: boolean;
}

/**
 * Forms Component
 */
@Component({
  selector: "app-queue",
  templateUrl: "./queue.component.html",
  styleUrls: ["./queue.component.scss"],
  providers: [QueueService],
})
export class QueueComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  statusList: QueueFilter[] = [];
  sourceSystemList: QueueFilter[] = [];

  public queueLogsForEnv: QueueLog[] = [];
  public filteredQueueLogs: QueueLog[] = [];

  public queueGridOptions: GridOptions;
  private dataSource: IDatasource;
  
  private gridApi;
  paginationSize = 20;

  initialRowDataLoad$;

  // Used to populate environment list on Unit Test and Run Test Groups tabs
  envList: any[];

  // Holds the environment name selected from the environment list on the Unit Test or Run Test Groups tab
  selectedEnv: string;

  currentDate: any = new Date();
  pastDate: any = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth(),
    this.currentDate.getDate() - 7,
    0,
    0,
    0,
    0
  );

  fromMinDate: any = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth(),
    this.currentDate.getDate() - 90,
    0,
    0,
    0,
    0
  );

  public myDatePickerFrom: any = this.pastDate;
  public myDatePickerTo: any = this.currentDate;

  cacheOverflowSize = 1;
  maxConcurrentDatasourceRequests = 1;
  infiniteInitialRowCount = 1;

  // Reactive form controls for the date range
  dateRange = new UntypedFormGroup({
    startDate: new UntypedFormControl(this.pastDate),
    endDate: new UntypedFormControl(this.currentDate),
  });

  rowModelType = 'infinite';

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private queueService: QueueService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.envList = EXECUTION_ENGINE_LIST;
    this.selectedEnv = '';
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.statusList = [
      { name: "Pending", selected: false },
      { name: "Success", selected: false },
      { name: "Error", selected: false },
    ];

    this.sourceSystemList = [
      { name: "Magic", selected: false },
      { name: "Oden", selected: false },
      { name: "BC9", selected: false },
      { name: "Standalone Issuance", selected: false },
    ];

    this.configureQueueGrid();
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  private configureQueueGrid(): void {
    this.initialRowDataLoad$ = [];
    this.queueGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "normal",
      //columnDefs: this.createHistoryColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      columnDefs: this.createQueueColDef(),
       //newly added
       cacheBlockSize : this.paginationSize,
       paginationPageSize : this.paginationSize,
       rowModelType : 'infinite',

      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
        radioFilterComponent : RadioFilterComponent,
        checkboxFilterComponent : CheckboxFilterComponent,
        datePickerFilterComponent : DatePickerFilterComponent
      },
      onGridReady: (params) => {
        //this.queueGridOptions.api.setRowData([]);
        this.queueGridOptions.api.sizeColumnsToFit();
        this.gridApi = params.api ;
        
        //newly added
        // this.queueGridOptions.cacheBlockSize = 100;
        // this.queueGridOptions.maxConcurrentDatasourceRequests = 1;
        // this.queueGridOptions.maxBlocksInCache = 5;
        this.dataSource = {
          getRows: (params: IGetRowsParams) => {
            //this.gridApi.showLoadingOverlay();
            const startRow = params.startRow;
            const endRow = params.endRow;
            const filterModel = params.filterModel;
            const dateFilterModel = params.filterModel['Timestamp'];

            if(this.selectedEnv) {
              this.queueService.getSelectedRecordsForEnv(this.selectedEnv, startRow, this.paginationSize, filterModel).subscribe((response: any) => {
                if (response && response.length > 0) {
                  if (response.length < this.paginationSize)
                    params.successCallback(response, startRow + response.length);
                  else {
                    params.successCallback(response.slice(0, 20));
                  }
                }
                else
                  params.successCallback([], 0); // No more rows to fetch
              });
            } else {
              params.successCallback([], 0); // No more rows to fetch
            }
          }
          
        };
        this.gridApi.setDatasource(this.dataSource);
      },
      onRowClicked: (event) => {
        this.onQueueRowClick(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
    
  }

   getDataFromServer(request, callback) {
    var startRow = request.startRow;
    var endRow = request.endRow;
    var filterModel = request.filterModel;

     if (this.queueGridOptions && this.queueGridOptions.api) {
       this.queueGridOptions.api.showLoadingOverlay();
       // need to send searchFilterConditions also to the api
       this.queueService.getSelectedRecordsForEnv(this.selectedEnv, startRow, endRow, filterModel).subscribe((response: any) => {
         var rowData = response.results;
         var lastRow = response.totalRecords <= endRow ? response.totalRecords : -1;
         callback(rowData, lastRow);
       });
     }
   }

  onQueueRowClick(e): void {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(QueueError, { windowClass: "xl" });
          modalRef.componentInstance.errorText = data.Error;
          modalRef.componentInstance.id = data.DealNumber;
          modalRef.componentInstance.callingSystem = data.CallingSystem;
          modalRef.componentInstance.env = this.selectedEnv;
          break;
        case "Details":
          const modalRefDetail = this._modal.open(QueueDetailComponent, {
            size: "xl",
          });
          modalRefDetail.componentInstance.log = e.data;
        //case "Retry":
        //  this.queueService.initiateRetry(this.selectedEnv, data.DealNumber, data.CallingSystem).subscribe(res => {
        //    e.event.target.src = "";
        //    this.toastr.info(res);
        //  },
        //    ex => {
        //      this.toastr.error(ex.error);
        //    });
      }
    }
  }

  onEnvChange(event): void {
    this.selectedEnv = event.Name;
    if (this.selectedEnv) {
      // Reset filters
      this.queueGridOptions.api.setFilterModel(null);
      this.gridApi.setDatasource(this.dataSource);
    }

  }

  createQueueColDef(): any[] {
    return [
      {
        headerName: "Timestamp",
        field: "Timestamp",
        resizable: true,
        width: 100,
        filter: "datePickerFilterComponent",
        filterParams: {
          value: { 'fromDate': this.pastDate, 'toDate': this.currentDate, 'fromMinDate': this.fromMinDate, 'toMaxDate': this.currentDate }
        },
        sort: "desc",
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleString() : "";
        },
      },
      {
        headerName: "ID (Deal #/Policy #/Orch ID)",
        field: "DealNumber",
        width: 160,
        resizable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ['equals'],
          suppressAndOrCondition: true
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if(params?.data?.DealNumber)
          return `<span>
            <a class="transaction-detail-link" data-action-type="Details">${params?.data?.DealNumber}</a>
          </span>`;
        },
      },
      {
        headerName: "System",
        field: "CallingSystem",
        width: 45,
        resizable: true,
        filter: 'checkboxFilterComponent',
        filterParams: {
          values: this.sourceSystemList.map(x=>x.name)
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Master Status",
        field: "Status",
        width: 50,
        resizable: true,
        filter: 'checkboxFilterComponent',
        filterParams: {
          values: this.statusList.map(x=>x.name)
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if (
            params?.data?.Status == "Success" ||
            params?.data?.Status == "Completed"
          ) {
            return `<img src="/assets/images/check-icon.png" title="Success">`;
          } else if (params?.data?.Status?.includes("Error")) {
            return `<img src="/assets/images/fail-icon.png" title="Error">`;
          } else if (params?.data?.Status != null) {
            return `<img src="/assets/images/warning-icon.png" title="Pending">`;
          }
        },
      },
      {
        headerName: "GhostDraft",
        field: "GhostDraftStatus",
        width: 40,
        resizable: true,
        filter: "",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if (params?.data?.GhostDraftStatus == "Success") {
            return `<img src="/assets/images/check-icon.png" title="Success">`;
          } else if (params?.data?.GhostDraftStatus == "Error") {
            return `<img src="/assets/images/fail-icon.png" title="Error">`;
          } else if (params?.data?.GhostDraftStatus != null) {
            return `<img src="/assets/images/warning-icon.png" title="Pending">`;
          }
        },
      },
      {
        headerName: "DMS",
        field: "ImageRightStatus",
        width: 25,
        resizable: true,
        filter: "",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if (params?.data?.ImageRightStatus == "Success") {
            return `<img src="/assets/images/check-icon.png" title="Success">`;
          } else if (params?.data?.ImageRightStatus == "Error") {
            return `<img src="/assets/images/fail-icon.png" title="Error">`;
          } else if (params?.data?.ImageRightStatus != null) {
            return `<img src="/assets/images/warning-icon.png" title="Pending">`;
          }
        },
      },
      {
        headerName: "Email",
        field: "EmailStatus",
        width: 25,
        resizable: true,
        filter: "",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if (params?.data?.EmailStatus == "Success") {
            return `<img src="/assets/images/check-icon.png" title="Success">`;
          } else if (params?.data?.EmailStatus == "Error") {
            return `<img src="/assets/images/fail-icon.png" title="Error">`;
          } else if (params?.data?.EmailStatus != null) {
            return `<img src="/assets/images/warning-icon.png" title="Pending">`;
          }
        },
      },
      {
        headerName: "Print",
        field: "PrintStatus",
        width: 25,
        resizable: true,
        filter: "",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if (params?.data?.PrintStatus == "Success") {
            return `<img src="/assets/images/check-icon.png" title="Success">`;
          } else if (params?.data?.PrintStatus == "Error") {
            return `<img src="/assets/images/fail-icon.png" title="Error">`;
          } else if (params?.data?.PrintStatus != null) {
            return `<img src="/assets/images/warning-icon.png" title="Pending">`;
          }
        },
      },
      {
        headerName: "Notify",
        field: "SysAttachStatus",
        width: 25,
        resizable: true,
        filter: "",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: function (params) {
          if (params?.data?.SysAttachStatus == "Success") {
            return `<img src="/assets/images/check-icon.png" title="Success">`;
          } else if (params?.data?.SysAttachStatus == "Error") {
            return `<img src="/assets/images/fail-icon.png" title="Error">`;
          } else if (params?.data?.SysAttachStatus != null) {
            return `<img src="/assets/images/warning-icon.png" title="Pending">`;
          }
        },
      },
      {
        headerName: "Error",
        width: 25,
        suppressMenu: true,
        suppressSorting: true,
        cellRenderer: function (params) {
          console.log(params?.data);
          if (params?.data?.Error != "Pass" && params?.data?.Error != null) {
            return `<img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          }
          return "";
        },
      },
      {
        headerName: "Retry",
        width: 25,
        suppressMenu: true,
        suppressSorting: true,
        cellRenderer: function (params) {
          console.log(params?.data);
          if (params?.data?.Status != "Success") {
            return `<img src="/assets/images/retry.png" data-action-type="Retry" class="cursor_pointer" title="Retry" width="12" height="12">`;
          }
          return "";
        },
      }
    ];
  }

  generateReport() {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = [
      "Timestamp",
      "ID",
      "CallingSystem",
      "FileName",
      "DocumentTitle",
      "CallbackUrl",
      "Status",
      "GhostDraft",
      "ImageRight",
      "Email",
      "Print",
      "Error",
    ];

    // api call here
    this.queueService.getSelectedRecordsForEnv(this.selectedEnv, 0, null, this.queueGridOptions.api.getFilterModel()).subscribe((response: any) => {
      this.filteredQueueLogs = response;
      let csv: string[] = [];
      csv.push(header.join(","));
      if (this.filteredQueueLogs.length == 0) { 
        for (var log of this.queueLogsForEnv) {
          if (log.Error != null) {
            log.Error = log.Error.replace(/"/g, "'");
          }
          log.Error = `"` + log.Error + `"`;
          csv.push(
            log.Timestamp.toString() +
            "," +
            log.DealNumber +
            "," +
            log.CallingSystem +
            "," +
            log.DocumentName +
            "," +
            log.DocumentTitle +
            "," +
            log.CallbackUrl +
            "," +
            log.Status +
            "," +
            log.GhostDraftStatus +
            "," +
            log.ImageRightStatus +
            "," +
            log.EmailStatus +
            "," +
            log.PrintStatus +
            "," +
            log.Error
          );
        }
      } else {
        for (var log of this.filteredQueueLogs) {
          if (log.Error != null) {
            log.Error = log.Error.replace(/"/g, "'");
          }
          log.Error = `"` + log.Error + `"`;
          csv.push(
            log.Timestamp.toString() +
            "," +
            log.DealNumber +
            "," +
            log.CallingSystem +
            "," +
            log.DocumentName +
            "," +
            log.DocumentTitle +
            "," +
            log.CallbackUrl +
            "," +
            log.Status +
            "," +
            log.GhostDraftStatus +
            "," +
            log.ImageRightStatus +
            "," +
            log.EmailStatus +
            "," +
            log.PrintStatus +
            "," +
            log.Error
          );
        }
      }

      let csvArray = csv.join("\r\n");
      var blob = new Blob([csvArray], { type: "text/csv" });
      saveAs(blob, `DocumentQueueStatusReport_${this.selectedEnv}.csv`);
    });

  }
}
