<div class="component-container">
  <div class="component-heading-container d-flex align-items-center justify-content-between">
    <h3 class="component-heading-text">Deploy Packages</h3>
    <div style="margin: 10px">
      <button
        type="button"
        mat-raised-button
        class="btn-teal"
        (click)="packageReport()"
      >
        Create Package Report
      </button>
     
  </div>
  
  <!-- <div style="margin: 10px">
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="packageReport()"
    >
      Create Package Report
    </button>
    -->
  </div>
   
  <div class="component-content-view" style="height: calc(100vh - 150px); overflow-y: auto;">
    <div class="container-fluid">
      <div id="deploy-container" class="row">
        <div
          id="deploy-content-container"
          class="col-12"
          style="overflow: visible; min-height: 100px"
        >
          <ag-grid-angular
            class="ag-theme-balham fast_panel_table"
            style="
            height: calc(100vh - 339px) !important;
            border-radius: 7px;
            box-shadow: 0 0 7px #fff;
          "
            [gridOptions]="deployGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <!-- <div id="forms-container" class="row">
      <label class="label_heading"
        >Deployment History (Selected Package) :</label
      >
      <div
        id="forms-content-container"
        class="col-md-12"
        style="overflow: visible; min-height: 100px"
      >
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          style="
          height: calc(100vh - 497px) !important;
          overflow-x: hidden !important;
          border-radius: 7px;
          box-shadow: 0 0 7px #fff;
        
        "
          [gridOptions]="historyGridOptions"
        >
        </ag-grid-angular>
      </div>
    </div> -->
    <div id="forms-container" class="row">
      <label class="label_heading">Forms (Selected Package) :</label>
      <div
        id="forms-content-container"
        class="col-md-12"
        style="overflow: visible; min-height: 100px"
      >
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          style="
          height: calc(100vh - 427px) !important;
          overflow-x: hidden !important;
          border-radius: 7px;
          box-shadow: 0 0 7px #fff;
        
        "
          
          

          [gridOptions]="formsGridOptions"
        >
        </ag-grid-angular>
      </div> 
  </div>
  <div style="font-size: 12px; margin-left: 7px; color: red;">
   *  Packages highlighted in yellow have been deployed for testing but have not been deployed to PROD in over 15 days.
</div>
</div>
