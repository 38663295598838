// Angular Imports
import { Component } from "@angular/core";

// Third Party Imports
import { ICellRendererAngularComp } from "ag-grid-angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";

// Internal Imports
import { DeployPackage } from "../../models";
import { DeployConfirmationComponent } from "../deploy-confirmation";

/**
 * Deploy Button Component
 * */
@Component({
  selector: "app-deploy-button",
  template: `<div style="text-align:left">
    <button
      mat-raised-button
      class="btn-teal"
      style="min-width: auto;font-size:12px"
      [disabled]="packageData.IsDeployDisabled"
    >
      Deploy
    </button>
  </div>`,
})
  
export class DeployButtonComponent implements ICellRendererAngularComp {
  //Private Variables
  /**
   * Grid Params
   */
  private _params: any;

  // Public Variables
  /**
   * Package Data
   */
  public packageData: DeployPackage;

  //Constructor
  /**
   * Constructor
   * @ignore
   */
  constructor(private _modal: NgbModal, private toastr: ToastrService) {}

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {}

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  // Public Methods
  // ICellRendererAngularComp methods start
  /**
   * AgInit
   * @param params
   */
  agInit(params: any): void {
    this._params = params;
    this.packageData = params.data;
    this.packageData.IsDeployDisabled = true;
  }

  /**
   * Refresh
   * @param params
   */
  refresh(params: any): boolean {
    return false;
  }

  // ICellRendererAngularComp methods end

  /**
   * To open deploy package pop-up
   * */
  deployPackage(): void {
    if (!this.packageData.IsDeployDisabled) {
      const modalRef = this._modal.open(DeployConfirmationComponent, {
        backdrop: "static",
      });
      modalRef.componentInstance.packageToBeDeployed = _.cloneDeep(
        this.packageData
      );
      modalRef.result.then((result: any) => {
        if (result) {
          this._params.context.componentParent.getPackages();
        }
      });
    } else {
      this.toastr.warning(
        "Please select environments to which the package has to be deployed.",
        "Warning"
      );
    }
  }
}
