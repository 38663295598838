import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { ImportQueueService } from "../../services/import-queue.service";
import { UpdateContext } from "../../models/update-context";
import { UpdatePayload } from "../../models/update-payload";
import { UpdateTransactionsResponse } from '../../models/update-transaction-response';
import { UserProfileService } from "../../../framework";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-transactions',
  templateUrl: './update-transactions.component.html',
  styleUrls: ['./update-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateTransactionsComponent implements OnInit {

  private columnApi: any;
  updateContext: UpdateContext;
  actionOptions: any[] = [
    { label: "Lost", value: "Lost", modelRequired: false },
    { label: "Resolved", value: "On Hold", modelRequired: false },
    { label: "Retry", value: "Retry", modelRequired: true }
  ];
  columnDefs: ColDef[];
  pagination: boolean = false;
  errors: string[] = [];
  inProgress: boolean = false;
  hasUpdateFailures: boolean = false;
  selectedCount: number;
  failedCount: number;

  updateForm = new FormGroup({
    requestedAction: new FormControl<string>( '', [ Validators.required ] ),
    note: new FormControl<string>('', [ Validators.required, Validators.maxLength(1000) ] )
  });

  @Output() triggerDetailUpdate = new EventEmitter();
  @Output() triggerQueueUpdate = new EventEmitter();

  @ViewChild('updateTransactionsModal') updateTransactionsModal: any;

  constructor(private queueService: ImportQueueService, private modalService: NgbModal, private userProfileService: UserProfileService, private snackBar: MatSnackBar ) {
    this.columnDefs = [
      { field: 'productTypeId', headerName: "Policy/Claim Number", sortable: true, filter: true, resizable: true },
      { field: 'productType', headerName: "Type", sortable: true, filter: true, resizable: true },
      { field: 'status', headerName: "Current Status", sortable: true, filter: true, resizable: true },
      { field: 'errorMessage', headerName: "Error Message", sortable: true, filter: true, resizable: true, width: 400, hide: true },
      { field: 'transactionId', headerName: "Transaction ID", sortable: true, filter: true, resizable: true, width: 400 }
    ];
  }

  ngOnInit(): void {}

  onGridReady(params: any): void {
    this.columnApi = params.columnApi; 
  }

  dismissModal(): void {
    this.hasUpdateFailures = false;
    this.modalService.dismissAll();
  }

  openModal(updateContext: UpdateContext): void {
    this.updateContext = updateContext;
    this.performReset();
    this.modalService.open( this.updateTransactionsModal, { backdrop: 'static', size: 'xl' } );
  }

  performReset() {
    this.inProgress = false;
    this.errors = [];
    this.updateForm.controls.requestedAction.reset('');
    this.updateForm.controls.note.reset('');
  }

  performUpdate(): void {
    this.inProgress = true;
    this.errors = [];
    let hasErrors = ( (this.updateContext.action == "requestAction" && this.updateForm.controls.requestedAction.errors) || this.updateForm.controls['note'].errors );
   
    if (hasErrors) {

      if (this.updateContext.action == "requestAction" && this.updateForm.controls.requestedAction.errors && this.updateForm.controls.requestedAction.errors["required"] == true) {
        this.errors.push("You must select an action.");
      }
      if (this.updateForm.controls.note.errors && this.updateForm.controls.note.errors["required"] == true) {
        this.errors.push("You must enter a note.");
      }
      if (this.updateForm.controls.note.errors && this.updateForm.controls.note.errors["maxlength"]) {
        this.errors.push("Your note cannot be longer than " + this.updateForm.controls.note.errors["maxlength"].requiredLength + " characters.");
      }
      this.inProgress = false;

    } else {

      let updatePayload: UpdatePayload = {
        transactionIds: [],
        notes: this.updateForm.controls.note.value,
        user: this.userProfileService.UserName
      }

      if (this.updateContext.action == "requestAction") {
        updatePayload.action = this.updateForm.controls.requestedAction.value;
        updatePayload.actionLabel = this.updateForm.controls.requestedAction.value == "On Hold" ? "Resolved" : this.updateForm.controls.requestedAction.value;
      }

      this.updateContext.transactions.forEach((item: any) => {
        updatePayload.transactionIds.push(item.transactionId);
      });

      this.queueService.updateTransactions( updatePayload, this.updateContext.context )
        .subscribe(
          // Handle success response
          updateResponse => {

            //handle update submission error(s)
            let responseDict = new Map<string, UpdateTransactionsResponse>();
            updateResponse.body.UpdateTransactionsResponse.forEach((response: UpdateTransactionsResponse) => {
              responseDict.set(response.TransactionId, response);
            });

            let failedTransactions: any[] = [];
            this.updateContext.transactions.forEach((transaction: any) => {
              const match = responseDict.get(transaction.transactionId);
              if (match !== undefined && match.RequestStatus == "Failed Submission") {
                failedTransactions.push({
                  ...transaction,
                  errorMessage: match.Reason
                })
              }
            });

            if (failedTransactions.length !== 0) {

              this.inProgress = false;
              this.hasUpdateFailures = true;
              this.selectedCount = updateResponse.body.UpdateTransactionsResponse.length;
              this.failedCount = failedTransactions.length;
              this.updateContext.transactions = failedTransactions;
              this.columnApi.setColumnVisible('errorMessage', true);

            } else {

              if (this.updateContext.context == 'detail') {
                this.triggerDetailUpdate.emit(updatePayload);
              } else {
                this.triggerQueueUpdate.emit();              
              }
              this.snackBar.open("Update successful", "Close", { duration: 1000, panelClass: ['mdo-snack-msg'] });
              
              this.inProgress = false;
              this.modalService.dismissAll();
            }
          },
          // Handle an error response
          error => {
            this.errors.push("The update attempt failed.  Please try again.");
            this.inProgress = false;
          }
        );
     
    }
   
  }

}
