<div class="modal-header">
  <h4 class="modal-title">{{ actionType }} Test Case</h4>
</div>

<div class="modal-body col-12">
  <!-- Fields -->
  <div class="row" style="padding-bottom: 2px">
    <div class="col-md-6">
      <div class="col-md-12 mrgbtm10">
        <label style="font-weight: bold">Name</label>
        <textarea
          id="txtName"
          style="width: 100%"
          rows="2"
          [(ngModel)]="testCase.Name"
        ></textarea>
      </div>
    </div>
      <div class="col-md-6">
        <div class="col-md-12 mrgbtm10">
          <label style="font-weight: bold">Description</label>
          <textarea
            id="txtDescription"
            rows="2"
            style="width: 100%"
            [(ngModel)]="testCase.Description"
          ></textarea>
        </div>
      </div>

  </div>
  <div class="row" style="padding-bottom: 2px">
        <div class="col-md-3" disabled>
          <div class="col-md-12 mrgbtm10">
            <label style="font-weight: bold">Company</label>
            <input
              id="txtWritingCompany"
              style="width: 100%"
              [(ngModel)]="testCaseWritingCompany"
            />
          </div>
        </div>
          <div class="col-md-3" disabled>
           <div class="col-md-12 mrgbtm10">
            <label style="font-weight: bold">State</label>
            <input
              id="txtState"
              style="width: 100%"
              [(ngModel)]="testCaseState"
            />
          </div>
         </div>
        
 
        <div class="col-md-3">
          <div class="col-md-12 mrgbtm10">
            <label style="font-weight: bold">Policy Effective Date</label>
            <date-picker
              name="txtEffectiveDate"
              id="txtEffectiveDate"
              style="width: 100%"
              [(ngModel)]="testCasePolicyEffectiveDate"
            ></date-picker>
          </div>
        </div>
      </div>
     

      <div class="row" style="padding-bottom: 2px">
        <!-- Left Side -->
        <div class="col-md-12 mrgbtm4">
          <div
            id="test-group-grid-container"
            class="col-12"
            style="padding-bottom: 10px;"
          >
            <div style="padding-bottom: 5px"><b>Test Groups</b></div>
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="testGroupGridOptions"
              style="
              height: calc(100vh - 423px) !important;
              overflow-x: hidden !important;
              border-radius: 7px;
              box-shadow: 0 0 7px #fff;
            
            "
            >
            </ag-grid-angular>
          </div>
        </div>
        <div class="col-md-6 mrgbtm4">
          <div id="lob-grid-container" class="col-12" style="padding-top: 1px">
            <div style="padding-bottom: 5px"><b>Lines of Business</b></div>
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="lobGridOptions"
               style="
        height: calc(100vh - 423px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      
       "
            >
            </ag-grid-angular>
          </div>
        </div>
        <div class="col-md-6 mrgbtm4">
          <div
            id="coverage-grid-container"
            class="col-12"
            style="padding-bottom: 10px"
          >
            <div style="padding-bottom: 5px"><b>Coverages</b></div>
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="coverageGridOptions"
              style="
              height: calc(100vh - 423px) !important;
              overflow-x: hidden !important;
              border-radius: 7px;
              box-shadow: 0 0 7px #fff;
            
            "
            >
            </ag-grid-angular>
          </div>
        </div>
        <div class="col-md-12 mrgbtm4 ml-3">
          <div class="wrapper">
          <label style="font-weight: bold">CPM</label>
          <button type="button" mat-raised-button class="btn primary_btn" (click)="importCpmForms()">
            Import Forms From CPM
          </button>
          </div>
          <textarea
            id="txtCpm"
            rows="10"
            style="width: 97%"
            required
            [(ngModel)]="this.testCaseCPM"
            #txtCpm="ngModel"
          ></textarea>
        </div>
        <div class="col-md-12 mrgbtm4">
          <label style="font-weight: bold;margin-left: 17px;">Forms</label>
          <div class="col-md-12">
           
            <ag-grid-angular
              class="ag-theme-balham fast_panel_table"
              [gridOptions]="selectedTestCaseFormsGridOptions"
              style="
              height: calc(100vh - 423px) !important;
              overflow-x: hidden !important;
              border-radius: 7px;
              box-shadow: 0 0 7px #fff;
            
            "
            >
            </ag-grid-angular>
           
          </div>
        </div>
      </div>
      </div>
<div class="modal-footer">
  <button type="button" mat-raised-button class="btn secondary_btn mr-3"  (click)="activeModal.close()">
    Cancel
  </button>
  <button type="button"  mat-raised-button class="btn primary_btn" (click)="saveTestCase()">
    Save
  </button>
</div>
