import { OnInit, OnDestroy, Component, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

// Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import {
  ConfirmationModalComponent,
  DateTimePipe,
  DateFilterComponent,
  BooleanFilterComponent,
  SharedService,
  AuthorizationService,
  UserProfileService,
  CPMElement,
  CPMPaths,
} from "../../../framework";
import { CPMService } from "../../services/cpmService";
import { AddCPMElementComponent } from "../add-cpm";
import { AddCPMFieldComponent } from "../add-cpm-field";

@Component({
  selector: "cpmelements",
  templateUrl: "./cpmelements.component.html",
  styleUrls: ["./cpmelements.component.scss"],
})
export class CPMElementsComponent implements OnInit, OnDestroy {
  //Public Variables
  /**
   * width
   */
  @HostBinding("style.width") width: string;

  /**
   * isDirty Flag to indicate that the user must save
   */
  isDirty: boolean;

  /**
   * Previous CPM element Id
   */
  PreviousCPMElementId: string;

  /**
   * Current CPM element
   */
  CurrentCPMElement: CPMElement;

  CurrentCPMPaths: CPMPaths[];

  /**
   * Modified CPM elements
   */
  cPMElements: CPMElement[];

  /**
   * Original CPM element
   */
  OriginalCPMElements: CPMElement[];

  /**
   * CPM grid options
   */
  cpmGridOptions: GridOptions;

  /**
   * All CPM modified since last save.
   */
  dirtyCPMList: string[] = [];

  /**
   * initialRowDataLoad$
   */
  initialRowDataLoad$;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private route: Router,
    private cpmService: CPMService,
    private _modal: NgbModal,
    private dateTimePipe: DateTimePipe,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private userProfileService: UserProfileService
  ) {
    this.width = "100%";
  }

  get CPMWritePermission(): boolean {
    return this.authService.RulesWrite();
  }

  ngOnDestroy(): void {
    //Check if there are any changes then save the changes on navigation
    if (this.isDirty) {
      // Always going to be update as we are not allowing the user to add rule type
      this.cpmService
        .updateCPMElement(this.CurrentCPMElement)
        .subscribe((result) => {
          //Reset the isDirty Flag
          this.isDirty = false;
          this.toastr.success(
            "CPM Element Autosaved Successfully",
            "Rule Type"
          );
        });
    }
  }

  ngOnInit(): void {
    this.isDirty = false;

    // Configure the grid
    this.configureGrid();

    //Get the rule typr detail List
    this.getAllCpmElements(true);
  }

  /**
   * Configure grid
   * */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.cpmGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 18,
      enableColResize: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.cpmGridOptions.api.setRowData([]);
        this.cpmGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }
  /**
   * Configure grid columns
   * @returns {any[]} Grid Column Array
   * */
  private createColumDef(): any[] {
    let result: any[] = [
      { headerName: "Code", field: "Code",  tooltipValueGetter: (params) => params.value,
      headerTooltip: "Code",filter: "agTextColumnFilter" },
      {
        headerName: "Description",
        field: "Description",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Description",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "E2 Description",
        field: "e2Description",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "E2 Description",
        filter: "agTextColumnFilter",
      },
    ];

    return result;
  }

  /**
   * Row click event when user click on a row in grid
   * @param {any} e event
   */
  private onRowClicked(e: any): void {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");

      //switch (actionType) {
      //  case "Edit":
      //    return this.editCPM('Edit', data, e.rowIndex);
      //  case "Copy":
      //    return this.editCPM('Copy', data);
      //}
    }
  }

  // Private methods
  /**
   * Get CPMElements type details list
   * @param {boolean} isPageInit
   */
  private getAllCpmElements(isPageInit: boolean): void {
    this.cpmService.getAllCpmElements().subscribe((res: CPMElement[]) => {
      this.cPMElements = res;
      this.cPMElements = this.cPMElements.sort((a, b) =>
        a.CPMDataElementName.localeCompare(b.CPMDataElementName)
      );
      this.OriginalCPMElements = _.cloneDeep(this.cPMElements);
      //On Page Load Rule set type to first one
      if (
        this.cPMElements != null &&
        this.cPMElements.length > 0 &&
        isPageInit
      ) {
        this.onCPMelementClick(this.cPMElements[0]);
      }
    });
  }

  /**
   * Sets the ruleType as currentRuleTypeDetail
   * @param index index
   * @param value value
   * @returns {number} index
   */
  public onCPMelementClick(cpmElement: CPMElement): void {
    // If there are existing changes then ask for confirmation
    if (this.isDirty) {
      this.openConfirmation(cpmElement);
    }
    //  else change the cpm element
    else {
      this.changeCPMelement(cpmElement);
    }
  }

  /**
   * Open Confirmation
   * @param CurrentCPMElement
   **/
  private openConfirmation(cpmElement: CPMElement): void {
    // Don't trigger confirmation if user clicked the same CPM element
    if (
      cpmElement.CPMDataElementName == this.CurrentCPMElement.CPMDataElementName
    ) {
      return;
    }
    let dialog: any = {
      ok: "Yes",
      cancel: "No",
      title: "Unsaved Changes",
      content:
        "You haven't saved your changes. Are you sure you want to change the CPM Element?",
    };
    const modalRef = this._modal.open(ConfirmationModalComponent, {
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.dialog = dialog;

    modalRef.result.then(
      (response: any) => {
        // Cancel all the unsaved changes
        this.cancelChanges();

        // Change the ruleset
        this.changeCPMelement(cpmElement);
      },
      (reason: any) => {
        //console.log(reason);
      }
    );
  }

  /**
   * Undo the changes
   *
   **/
  private cancelChanges(): void {
    // Set the is dirty as false
    this.isDirty = false;

    // Set the CPM list as original to undo all the changes
    this.cPMElements = _.cloneDeep(this.OriginalCPMElements);

    let index = _.findIndex(this.OriginalCPMElements, (row, index) => {
      return row.Id == this.PreviousCPMElementId;
    });

    this.CurrentCPMElement = this.OriginalCPMElements[index];
    this.setRowData();
  }

  /**
   * Change the CPM element
   * @param cpmElement
   */
  private changeCPMelement(cpmElement: CPMElement): void {
    if (this.CurrentCPMElement == null) {
      //Assign Previous CPM Id as the given ruleset name when the current set type is null which only for first time
      this.PreviousCPMElementId = cpmElement.Id;
    } else {
      //Set the previous rule type
      this.findAndSetAsPreviousCPMID();
    }

    //Set the current rule type detail
    this.CurrentCPMPaths = [];
    this.CurrentCPMElement = cpmElement;
    
    for (var i = 0; i < this.CurrentCPMElement.CPMExpressions.length; i++) {
      var tempPath = new CPMPaths();
      tempPath.path = this.CurrentCPMElement.CPMExpressions[i].CPMExpression;
      tempPath.pathType = this.CurrentCPMElement.CPMExpressions[i].MessageType;
      tempPath.pathVersion = this.CurrentCPMElement.CPMExpressions[i].CPMVersion;
      
      if (tempPath.pathVersion.toString() == "3") {
        tempPath.path = tempPath.path.replace(/\//g, ".");
      }
      this.CurrentCPMPaths.push(tempPath);
    }
    if (!this.CurrentCPMElement.CPMFieldValues) {
      this.CurrentCPMElement.CPMFieldValues = [];
    }
    this.setRowData();
  }

  /**
   * Get CPM fields for selected CPM
   * */
  private setRowData(): void {
    if (this.CurrentCPMElement != null) {
      this.CurrentCPMElement.CPMFieldValues.sort((a: any, b: any) => {
        var nameA = a.Code.toLowerCase();
        var nameB = b.Code.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.cpmGridOptions.api.setRowData(this.CurrentCPMElement.CPMFieldValues);
    }
  }

  /**
   * Check If the Object is dirty
   * @param newValue
   * @param oldValue
   * @returns {boolean} true or false
   */
  private checkIsDirty(newValue: any, oldValue: any): boolean {
    let isDirty = false;
    Object.keys(newValue).forEach((key) => {
      if (
        key != "ModifiedDate" &&
        key != "ModifiedByName" &&
        key != "CreationDate" &&
        key != "CreatedByName" &&
        newValue[key] != oldValue[key]
      ) {
        isDirty = true;
      }
    });
    return isDirty;
  }

  /**
   * find the previous id from original and set the value as previous
   * */
  private findAndSetAsPreviousCPMID(): void {
    //Find if the existing CPM is present in the original list then set previous cpm ID
    let index = _.findIndex(this.OriginalCPMElements, (row, index) => {
      return row.Id == this.CurrentCPMElement.Id;
    });
    if (index > -1) {
      this.PreviousCPMElementId = this.CurrentCPMElement.Id;
    }
  }

  /**
   * To open cpm element detail Add/Edit pop-up
   * @param actionType actionType
   * @param cpmToModify CPMelement
   * @param index index
   */
  public openCPMElement(actionType: string, cpmToModify: any = null): void {
    //Open the popup for the rule
    const modalRef = this._modal.open(AddCPMElementComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.currentCPMElement = _.cloneDeep(cpmToModify);

    modalRef.componentInstance.actionType = actionType;
    modalRef.result.then((result: any) => {
      if (result) {
        this.isDirty = false;
        this.getAllCpmElements(false);
        this.onCPMelementClick(result);
      }
    });
  }

  /**
   * To open cpm field detail Add pop-up
   * @param actionType actionType
   * @param ruleDetail ruleDetail
   * @param rule rule
   * @param index index
   */
  public openCPMField(
    actionType: string,
    cpmToModify: any = null,
    index: number = null
  ): void {
    //Open the popup for the rule
    const modalRef = this._modal.open(AddCPMFieldComponent, {
      size: "lg",
      backdrop: "static",
    });
    //modalRef.componentInstance.ruleTypeDetail = _.cloneDeep(ruleDetail);
    modalRef.componentInstance.currentCPMElement = _.cloneDeep(cpmToModify);

    modalRef.componentInstance.actionType = actionType;
    modalRef.componentInstance.index = index;
    modalRef.result.then((result: any) => {
      if (result) {
        this.isDirty = true;
        this.CurrentCPMElement = result;
        let index = _.findIndex(this.cPMElements, {
          CPMDataElementName: this.CurrentCPMElement.CPMDataElementName,
        });

        this.cPMElements[index] = this.CurrentCPMElement;

        //Reload the grid
        this.changeCPMelement(this.CurrentCPMElement);
      }
    });
  }

  /**
   * Update the CPM detail
   *
   **/
  updateCPMDetail(): void {
    this.cpmService.updateCPMElement(this.CurrentCPMElement).subscribe(
      (result) => {
        this.isDirty = false;
        this.getAllCpmElements(false);
        this.onCPMelementClick(result);
        this.toastr.success("CPM Element Data Saved Successfully", "CPM");
      },
      (error) => {
        this.toastr.error(
          "Error occurred while updating CPM Element form.",
          "CPM"
        );
      }
    );
  }

  /**
   * On cancel button clicked
   *
   **/
  onCancelClicked(): void {
    this.cancelChanges();
    this.findAndSetAsPreviousCPMID();
  }
}
