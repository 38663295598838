<div class="component-container">
  <div class="component-heading-container d-flex align-items-center justify-content-between">
    <h3 class="component-heading-text">
      CPM Mapping - Changes made on this page require running the Copy Reference
      Collections script.
    </h3>
    <div class="btn_wrapper">
      <button
        *ngIf="isDirty"
        class="btn secondary_btn btnlignpd"
        type="button"
        (click)="onCancelClicked()"
      >
        Cancel
      </button>
      <button
        *ngIf="CPMWritePermission"
        [disabled]="!isDirty"
        mat-raised-button
        class="btn-teal mrgrgt10 mt-0"
        type="button"
        (click)="updateCPMDetail()"
      >
        Save
      </button>
    </div>
  </div>
  <div
    class="component-content-view"
    style="display: flex !important; overflow-y: unset !important"
  >
    <div id="rule-type-container" class="col-12 col-sm-3 col-md-2 col-xl-2">
      <div
        class="heading-container float_none"
        id="rule-type-heading-container"
      >
        <h4 class="heading-value" id="rule-type-heading">CPM Elements</h4>
        <span
          *ngIf="CPMWritePermission"
          class="rule-type-btn"
          id="rule-type-add-btn"
          (click)="openCPMElement('Add')"
        >
          <i class="fas fa-plus"></i>
        </span>
        <span
          *ngIf="CPMWritePermission"
          class="rule-type-btn"
          id="rule-type-add-btn"
          (click)="openCPMElement('Edit', CurrentCPMElement)"
        >
          <i class="fas fa-edit"></i>
        </span>
      </div>
      <div
        id="rule-type-content-container"
        style="overflow-y: auto; height: 570px; max-height: calc(100vh - 166px)"
      >
        <ul id="rule-type-content-list">
          <li
            *ngFor="let cpmElement of cPMElements"
            [ngClass]="{
              active:
                cpmElement.CPMDataElementName ==
                CurrentCPMElement.CPMDataElementName
            }"
            (click)="onCPMelementClick(cpmElement)"
          >
            {{ cpmElement.CPMDataElementName }}
          </li>
        </ul>
      </div>
    </div>
    <div
      id="rule-rule-type-detail-container"
      class="col-12 col-sm-9 col-md-10 col-xl-10"
    >
      <div id="rule-type-detail-container" class="row">
        <div class="heading-container" id="rule-type-detail-heading-container">
          <h4 class="heading-value" id="rule-type-detail-heading-value">
            CPM Details
          </h4>
        </div>
        <div id="rule-type-detail-content-container" class="col-12" style="overflow-y:auto;top: 3px;">
          <div class="card" style="display:block; height:141px; overflow-y:auto" >
            <div id="rule-type-detail-content-card-body" class="card-body row pb-0 ">
              <div class="col-4 col-md-2">
                <label class="rule-type-detail-heading">Element Name</label>
                <label class="rule-type-detail-value">{{
                  CurrentCPMElement
                    ? CurrentCPMElement?.CPMDataElementName
                    : "&nbsp;"
                }}</label>
              </div>

              <div class="col-4 col-md-2">
                <label class="rule-type-detail-heading">Complex Type</label>
                <label class="rule-type-detail-value">{{
                  CurrentCPMElement ? CurrentCPMElement?.ComplexType : "&nbsp;"
                }}</label>
              </div>
              <div class="col-4 col-md-2">
                <label class="rule-type-detail-heading">Rule Type</label>
                <label class="rule-type-detail-value">{{
                  CurrentCPMElement
                    ? CurrentCPMElement?.RuleType == 0
                      ? "Simple Rule"
                      : "Derived Rule"
                    : "&nbsp;"
                }}</label>
              </div>
              <div class="col-4 col-md-2">
                <label class="rule-type-detail-heading">Created date</label>
                <label
                  class="rule-type-detail-value"
                  *ngIf="
                    CurrentCPMElement && CurrentCPMElement.CreationDate;
                    else nbsp
                  "
                  >{{ CurrentCPMElement.CreationDate | dateTimePipe }}</label
                >
                <!--DateTimePipe Examples-->
                <!--<label class="rule-type-detail-value">{{"1543258448" | dateTimePipe}}</label>-->
                <!--<label class="rule-type-detail-value">{{"1543258448" | dateTimePipe : 'string' : 'DD/MM/YYYY'}}</label>-->
              </div>
              <div class="col-4 col-md-2">
                <label class="rule-type-detail-heading">Modified date</label>
                <label
                  class="rule-type-detail-value"
                  *ngIf="
                    CurrentCPMElement && CurrentCPMElement.ModifiedDate;
                    else nbsp
                  "
                  >{{ CurrentCPMElement.ModifiedDate | dateTimePipe }}</label
                >
              </div>
              <ng-template #nbsp>
                <label class="rule-type-detail-value">&nbsp;</label>
              </ng-template>
            </div>
            <div id="rule-type-detail-content-card-body" class="card-body row pb-0" *ngFor="let path of CurrentCPMPaths">
              <div class="col-12 col-md-12">
                <label class="rule-type-detail-heading">CPM V{{path.pathVersion}} - {{path.pathType}}</label>
                <label class="rule-type-detail-value">
                  {{
                 path.path
                    ?path.path
                    : "&nbsp;"
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rule-container" class="row">
        <div class="heading-container" id="rule-heading-container">
          <h4 class="heading-value" id="rule-heading-value">CPM Fields</h4>
          <span
            *ngIf="CurrentCPMElement != null && CPMWritePermission"
            class="rule-type-btn"
            id="rule-type-add-btn"
            (click)="openCPMField('Add', CurrentCPMElement)"
          >
            <i class="fas fa-plus"></i>
          </span>
        </div>
        <div id="rule-content-container" class="col-12">
          <ag-grid-angular
            class="ag-theme-balham fast_panel_table"
            style="
            height: calc(100vh - 356px) !important;
            overflow-x: hidden !important;
            border-radius: 7px;
            box-shadow: 0 0 7px #fff;
          
          "
            [gridOptions]="cpmGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>

      <div></div>
    </div>
  </div>
</div>
