//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../framework";
import { ExecutionModule } from "../diagnostics/testSuites/execution/execution.module";
import { DeployRoutingModule } from "./deploy.routing";
import {
  DeployComponent,
  DeployConfirmationComponent,
  DeployButtonComponent,
  CopyButtonComponent,
  CopyConfirmationComponent,
} from "./components";
import { DeployService } from "./services";
import { UpdateButtonComponent } from "./components/update-button/update-button.component";
import { PackageReportComponent } from "./components/package-report/package-report.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DeployDetailviewComponent } from './components/deploy-componet-detailview/deploy-detailview.component';

/**
  Forms Module
*/
@NgModule({
  declarations: [
    DeployComponent,
    DeployConfirmationComponent,
    DeployButtonComponent,
    CopyButtonComponent,
    CopyConfirmationComponent,
    UpdateButtonComponent,
    PackageReportComponent,
    DeployDetailviewComponent,
  ],
  imports: [
    DeployRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FrameworkModule,
    ExecutionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    AgGridModule.withComponents([
      DateFilterComponent,
      BooleanFilterComponent,
      DeployButtonComponent,
      CopyButtonComponent,
      UpdateButtonComponent,
    ]),
    NgSelectModule,
  ],
  providers: [DeployService],
})
export class DeployModule {}
