//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../../../framework";
import { ExecutionRoutingModule } from "./execution.routing";
import {
  ExecuteRulesComponent,
  TestCaseDetail,
  TestGroupDetail,
  UpdateTestCase,
  TestMaintenance,
} from "./components";
import { TestCaseExpectedResult } from "./components/test-case-expected-result/test-case-expected-result.component";
import { ExecutionService } from "./services";

//Christian, 10/21/19: Had to add these individually because Visual Studio kept crying, might be able to remove later
import { ExecuteRulesSimpleComponent } from "../execution/components/exec-rules-simple/exec-rules-simple.component";
import { ExecuteRulesSimpleOpsComponent } from "../execution/components/exec-rules-simple-ops/exec-rules-simple-ops.component";
import { ExecuteResultsContainerComponent } from "../execution/components/exec-rules-results-container/exec-results-container.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { LogsModule } from "../../../logs/logs.module";
import { FailedTestDetails } from "./components/failed-test-details/failed-test-details.component";
import { MatButtonModule } from "@angular/material/button";
import { BulkUpdateTests } from "./components/bulk-update-tests/bulk-update-tests.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from '@angular/material/tooltip';

/**
  Forms Module
*/
@NgModule({
  declarations: [
    ExecuteRulesComponent,
    ExecuteResultsContainerComponent,
    ExecuteRulesSimpleComponent,
    ExecuteRulesSimpleOpsComponent,
    TestCaseDetail,
    TestCaseExpectedResult,
    UpdateTestCase,
    BulkUpdateTests,
    TestMaintenance,
    TestGroupDetail,
    FailedTestDetails,
  ],
  imports: [
    ExecutionRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
    MatButtonModule,
    LogsModule,
    MatTabsModule,
    MatTooltipModule,
  ],
  providers: [ExecutionService],
})
export class ExecutionModule {}
