
<div class="container-fluid">
  <div id="policy-form-container" class="row">
  
    <div class="row" style="margin-bottom: 5px;margin-left: 1px;">
     

      <div class="col-md-4 fast_admin">
        
        <div class="selectdiv select_wrapinline">
          <label>
            <ng-select
              id="ruleSetupList"
              class="custom-select"
              style="width: 100%"
              [items]="currentForm.RuleSetups"
              bindLabel="Name"
              [(ngModel)]="currentRuleSetup"
              (change)="onChangeRuleSetup($event)"
            >
            </ng-select>
          </label>
        </div>
      </div>

      <div
        class="btn_wrapper col-md-8"
        style="margin-top: 5px; "
      >

       
        <div class="d-flex justify-content-end align-items-end custom-padding">
                  
          <div ngbDropdown class="d-inline-block ml-2">
            <button
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
              style="border: none; box-shadow: 0 0 3px #000; color: #000;font-size: 14px;"
            >
              Add Rule
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="dropdown-item" (click)="onAddRule('basic_attach')">
                Basic Attach
              </button>
              <button class="dropdown-item" (click)="onAddRule('basic_remove')">
                Basic Remove
              </button>
            </div>
          </div>
          <button
            type="button"
            mat-raised-button
            class="btn-teal ml-2"
            (click)="onSaveRuleSetup()"
            *ngIf="FormsWritePermission"
          >
            Save
          </button>
          <button
            type="button"
            mat-raised-button
            class="btn-teal ml-2"
            (click)="deleteRuleSetup()"
            *ngIf="FormsWritePermission"
          >
            Delete Rule Setup
          </button>
          <button *ngIf="allPanelsExpanded"
        (click)="toggleAllPanels()" 
        style="border: none; background: transparent;"
        matTooltip="Collapse Rules">
       <i class="fas fa-chevron-circle-up" style="color: var(--color-teal); font-size: 26px; padding-top: 5px;"></i>
        </button>

          <button *ngIf="!allPanelsExpanded"
          (click)="toggleAllPanels()" 
          style="border: none; background: transparent;"
          >

          <i class="fas fa-chevron-circle-down" style="color: var(--color-teal);  font-size: 26px; padding-top: 5px;"></i>
         </button>
         <div class="shared-document-info">
           
          <a (click)="toggleDocumentPanel($event)"
             matTooltip="Click to view shared documents"
             class="info-icon">
            <i class="fas fa-info-circle" style="margin-top: 5px;"></i>
          </a>
          
          <div class="document-panel" *ngIf="isDocumentPanelOpen">
            <ul>
              <li *ngFor="let doc of sharedDocuments">
                <a href="{{doc.url}}" target="_blank">{{doc.name}}</a>
              </li>
            </ul>
          </div>
        </div>
        <button
        class=""
        (click)="toggleFilterPanel($event)"
        style="border: none; background: transparent"  matTooltip="Filter"
        aria-label="Filter"
      >
        <i
          class="fas fa-filter"
          style="color: var(--color-teal) !important;block-size: 20px;"
        ></i>
       

      </button>

        </div>
      </div>
    
    <div class="col-md-12">
      <div
        class="row"
        style="padding: 0px; margin-bottom: 5px; font-size: 14px;margin-left: 15px;"
      >
       
        <b *ngIf="this.effectiveDate != 0"
          >Effective Date: {{ this.effectiveDate | dateTimeUtcPipe }}</b
        >
        <b *ngIf="this.expirationDate != 0" style="padding-left: 10px"
          >Expiration Date: {{ this.expirationDate | dateTimeUtcPipe }}</b
        >
        <b
          *ngIf="this.submissionExpirationDate != 0"
          style="padding-left: 10px"
        >
          Submission Expiration Date:
          {{ this.submissionExpirationDate | dateTimeUtcPipe }}
        </b>
      
      </div>
      <div class="row chip-container" *ngIf="selectedFilters.length > 0">
        <div *ngFor="let filter of selectedFilters" class="chip">
          {{ filter.CPMDataElementName }}: {{ filter.SelectedElementValue }}
          <span class="close-chip" (click)="removeSelectedFilter(filter)"
            >×</span
          >
        </div>
        
      </div>
    </div>
   
    <div
      style="
        width: 100%;
        display: inline-block;
        float: right;
        padding-left: 10px;
      "
    >
      <div class="container" #scrollContainer>
        <ng-container *ngIf="recordsFound; else noRecordsTemplate" style="overflow-y: visible;">
            <mat-accordion multi>
              
              <ng-container *ngFor="let rule of currentRuleSetup?.RuleSet?.Rules; let i = index"  style="overflow-y: visible;">
                <mat-expansion-panel [expanded]="allPanelsExpanded" style="overflow: visible;"
                [ngClass]="{'attach-rule': rule.Action === 'Attach', 'remove-rule': rule.Action === 'Remove'}">
                  <mat-expansion-panel-header >
                    <mat-panel-title >
                      Rule {{ i + 1 }} [{{ rule.Action === 'Attach' ? 'Attach Rule' : 'Remove Rule' }}]
                    </mat-panel-title>
                 
                  </mat-expansion-panel-header>
                  <app-form-page-rule
                    [currentRule]="rule"
                    (deleteRule)="onDeleteRule($event)"
                    (saveRule)="onSaveRule($event)"
                    (cloneRule)="onCloneRule($event)"
                    *ngIf="this.clearedFilters || ruleFilterPasses(rule)"
                  ></app-form-page-rule>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </ng-container>
          <ng-template #noRecordsTemplate>
            <p class="no-records-container">No records found.</p>
          </ng-template>
        </div>
      </div>
    </div>
   
    <div class="filter-panel" [class.open]="isFilterPanelOpen">
      <div class="filter-header">
        <h4 style="flex-grow: 1; margin: 0; margin-left: 70px">Filters</h4>
       
        <button class="close-icon pl-5" (click)="toggleFilterPanel($event)">
          &times;
        </button>
      </div>
      
    
      <div class="filter-container1">
        <div
          class="col-md-12"
          style="margin-top: 15px; margin-left: 3px; margin-right: 7px"
        >
        <div class="wrapper">
          <button class="btn_gdf_searchbar"></button>
          <input
        type="text"
        placeholder="Search filters"
        [formControl]="searchControl"
        
      />
        </div>
          <div class="overlay_deal_list card1" *ngFor="let filter of filteredFilters">
            <div class="col-md-12">
              <div class="d-flex align-items-center">
                <label
                  class="label_heading me-2"
                  style="
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >{{ filter.CPMDataElementName }}</label
                >
                <button class="btn-toggle" (click)="toggleFilter(filter)">
                  {{ filter.isOpen ? "-" : "+" }}
                </button>
              </div>
              <div *ngIf="filter.isOpen" class="filter-content">
             
                <div
                  class="selectdiv selectdiv-custom select_wrapinline"
                  style="max-width: 289px; overflow: hidden; margin-bottom: 9px"
                >
                  <label>
                    <ng-select
                      [items]="filter.ElementValues"
                      bindLabel="e2Description"
                      bindValue="Code"
                     
                      (change)="onFilterElementChange(filter)"
                      
                      class="custom-select"
                      
                      class="form-page-att-input"
                      style="width: 100%"
                      [multiple]="true"
                      [clearable]="true"
                     
                      appendTo="body"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        
                        <mat-checkbox [checked]="isSelected(item, filter)" (change)="onCheckboxChange(item, filter, $event)"    (click)="$event.stopPropagation()" >
                          {{ item.e2Description }}
                        </mat-checkbox>
                      </ng-template>
                    </ng-select>
                  </label>
                </div>
              </div>
            </div>
          </div>
         
          <div class="filter-footer">
            <button 
            type="button"
            mat-raised-button
            class="btn-teal ml-2" 
            (click)="clearAll()">
            Reset
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
