//Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

//Third Party Imports
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

//Internal Imports
import { FrameworkModule, BooleanFilterComponent } from "../framework";

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { LogsModule } from "../logs/logs.module";
import { ReportRoutingModule } from "./report.routing";
import { ReportComponent } from "./components/report.component";
import { ReportExpressionComponent } from "./components/report-expression";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { ReportExpressionDetailViewComponent } from './components/report-expression-detail-view/report-expression-detail-view.component';

/**
  Forms Module
*/
@NgModule({
  declarations: [ReportComponent, ReportExpressionComponent, ReportExpressionDetailViewComponent],
  imports: [
    ReportRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    FrameworkModule,
    MatButtonModule,
    AgGridModule.withComponents([BooleanFilterComponent]),
    MatButtonToggleModule,
    MatSlideToggleModule,
    LogsModule,
    MatMenuModule,
  ],
  providers: [],
})
export class ReportModule {}
