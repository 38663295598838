//Angular Imports 
import { Component, OnInit, Input } from '@angular/core';

//Third Party Imports 
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

//Internal Imports 
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms'
import { NoteService } from '../../services';
import { UserNote } from '../../models';
import { UserProfileService } from '../../../framework';
import { error } from 'util';

/**
 * Add Form Component
*/
@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})

export class AddNoteComponent implements OnInit {

  //Public Variables

  userNote: UserNote;
  @Input() public linkedForms: string[] = [];
  @Input() public linkedRules: string[] = [];
  @Input() public popupView: boolean = true;
  @Input() public category: string = "";
public formGroup: UntypedFormGroup;


  public readonly: boolean = false;



  /**
 * Constructor
 * @ignore
*/
  constructor(public activeModal: NgbActiveModal, private _modal: NgbModal, private fb: UntypedFormBuilder,
    private noteService: NoteService, private userProfileService: UserProfileService, private toastr: ToastrService) {
    this.userNote = new UserNote();
  }


  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit() {
    this.createFormBuilder();
  }


  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }


  /**
   * Create form for builder
   **/
  private createFormBuilder(): void {

    this.formGroup = this.fb.group({
      formStatus: "Active",
      noteText: [{ value: '', disabled: this.readonly }, [Validators.required]]
    });

  }

  onClose() {
    this.activeModal.close(false);
  }

  saveNote(linkedForms: string[], linkedRules: string[], category: string) {

    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).markAsDirty();
    });
    console.log(this.formGroup.get("noteText"));
    if (this.formGroup.get("noteText")?.value == null || this.formGroup.get("noteText")?.value == "") {
      this.toastr.error("Note is required.");
      return;
    }
    this.userNote.NoteText = this.formGroup.controls["noteText"].value.replace(/"/g, '\\"');
    this.userNote.NoteId = "00000000-0000-0000-0000-000000000000";
    this.userNote.CreatedByName = this.userProfileService.UserProfile.UserName;
    this.userNote.CreationDate = moment().unix();
    this.userNote.LinkedForms = linkedForms;
    this.userNote.LinkedRules = linkedRules;
    this.userNote.Category = category;

    this.noteService.addNote(this.userNote).subscribe(result => {
      this.activeModal.close(this.userNote);
    }, error => { this.toastr.error('Error occurred while adding note.', 'Notes'); });
  }

}

