<div class="component-content-view">
  <div class="container-fluid pr-0">
    <div id="test-maintenance-container" class="row">
      <div
        class="col-md-12"
        style="padding-left: 0; padding-right: 0"
        id="test-group-heading-container"
      >
        <div class="heading-container fast_admin">
          <label class="label_heading">Test Groups</label>
          <span
            class="policy-form-btn"
            id="test-group-add-btn"
            (click)="createTestGroup()"
          >
            <i class="fas fa-plus" style="float: right; margin-top: 20px"></i>
          </span>
        </div>
      </div>

      <div
        id="test-group-container"
        class="col-md-12"
        style="padding-left: 0; padding-right: 0"
      >
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="testGroupGridOptions"
          style="height: 310px"
        >
        </ag-grid-angular>
      </div>

      <div
        class="col-md-12"
        style="padding-left: 0; padding-right: 0"
        id="test-case-heading-container"
      >
        <div class="heading-container fast_admin">
          <label class="label_heading mt-0">Test Cases</label>
          <button  class="btn-teal"   mat-raised-button (click)="updateTests()" style="float:right!important">Update Selected Tests</button>
          <!--
            Disabling the add button for TestCase because we should not be adding them this way.  We should create test cases from log entires.
              This functionality is generating errors to the console so it will need to be fixed if we decide we need it.
            
            <span class="policy-form-btn" id="test-case-add-btn" (click)="createTestCase()">
              <i class="fas fa-plus" style="float: right;  margin-top:20px"></i>
            </span>
          -->
        </div>
      </div>
      <div
        id="test-case-container"
        class="col-md-12"
        style="padding-left: 0; padding-right: 0"
      >
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="testCaseGridOptions"
          style="height: 380px"
        >
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
