<div class="component-container p-0">
  <div class="component-heading-container p-0 d-flex  align-items-center"  style="margin-right:19px; padding-top: 2px !important; padding-bottom: 0px;">
    <div class="row"> 
     
         
        <mat-tab-group
          headerPosition="above"
          [(selectedIndex)]="selectedTabIndex"
          (selectedIndexChange)="onTabChanged($event)"
          >
          <!-- Tab for Test Forms -->
          <mat-tab label="Test Forms"    type="button"
          id="test_forms_section_btn"
          class="mat_btn_hov_act"
          mat-raised-button
          (click)="loadTestForms()"
          *ngIf="!this.isReadOnly">
            
              
            
           
          </mat-tab>
        
          <!-- Tab for Run Saved Tests -->
          <mat-tab label="Run Saved Tests" type="button"
          style="margin: 10px 10px 0 10px"
          id="test_execution_section_btn"
          class="mat_btn_hov_act"
          mat-raised-button
          (click)="loadRunSavedTests()"
          *ngIf="!this.isReadOnly">
            
          </mat-tab>
        
          <!-- Tab for Manage Tests -->
          <mat-tab label="Manage Tests"  type="button"
          id="reporting_section_btn"
          class="mat_btn_hov_act"
          mat-raised-button
          (click)="loadManageTests()"
          *ngIf="!this.isReadOnly">
            
          </mat-tab>
        </mat-tab-group>
        </div>
        <div class="row">
          <div class="btn_wrapper" style="margin-left: auto; margin-right: 3px">
          <button
            type="button"
            *ngIf="activeSection === 'Test Forms'"
            mat-raised-button
            class="btn-teal"
            (click)="searchLogs()"
          >
            Search Logs
          </button>
          <button
            type="button"
            *ngIf="this.canCreateTest"
            mat-raised-button
            class="btn-teal"
            (click)="createTestCase()"
          >
            Create Test From Results
          </button>
          <button
            type="button"
            *ngIf="activeSection === 'Run Saved Tests'"
            mat-raised-button
            class="btn-teal"
            (click)="runTests()" style="margin-right: 10px;"
          >
            Run Test Group
          </button>
          <button
            type="button"
            *ngIf="activeSection === 'Run Saved Tests' && showTestCases"
            mat-raised-button
            class="btn-teal"
            (click)="runTestCases()"
          >
            Run Selected Test Cases
          </button>
          <button
            type="button"
            mat-raised-button
            class="btn-clear-red"
            (click)="clearLogData()"
            *ngIf="logSelected"
          >
            Clear Log Data
          </button>
        </div>
      </div>
    </div>
    
  <div *ngIf="activeSection === 'Test Forms'" style="height: calc(100vh - 117px); overflow-y: auto; overflow-x: hidden;">
    <div class="container-fluid" style="padding: 10px">
      <!-- <div id="policy-form-container"  style="margin-left: 20px" class="row"> -->
        <div class="mat-tab-label-container  d-flex  align-items-center justify-content-end">
                   
          <mat-tab-group (selectedTabChange)="onSelectSystem($event)">
            <mat-tab label="Doc.Gen.Test Harness"></mat-tab>
            <!-- <mat-tab label="Document Attachment"></mat-tab> -->
            
          </mat-tab-group>
       
      </div>
     
      <div id="policy-form-container"  style="margin-left: 20px" class="row">
        
        <div
          class="col-md-12"
          *ngIf="!isFAST"
          style="padding-left: 0px; margin-top: 0px"
        >
          <div
            class="row heading-container fast_admin"
            id="policy-form-heading-container"
          >
            <div class="col-md-4">
              <label class="label_heading">Environment:</label>
              <div class="selectdiv select_wrapinline" style="max-width: 50%">
                <label>
                  <ng-select
                    class="custom-select"
                    [items]="envList"
                    bindLabel="Name"
                    bindValue="ID"
                    (change)="onEnvChange($event)"
                    [(ngModel)]="selectedEnv"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <label class="label_heading">Package:</label>
              <div class="selectdiv select_wrapinline" id="selectdiv-package">
                <label>
                  <ng-select
                    class="custom-select"
                    [items]="packageNames"
                    (change)="onPackageChange($event)"
                    [(ngModel)]="selectedPackage"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item }}">{{ item }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
            </div>

            <div class="col-md-4 p-0 d-flex  align-items-center">
  


   
    
        <div class="row">
         
          <div
            class="btn_wrapper"
            style="margin-left: auto; margin-right: 0px;margin-top: 5px;"
          >
          <button type="button"
          mat-raised-button
          class="btn-teal"
          (click)="mapAndAssembleMultiple()"
          style="margin-bottom: 0px; margin-right: 3px;">
    Generate Documents
  </button>
            <button
              type="button"
              class="mat_btn_hov_act"
              mat-raised-button
              [matMenuTriggerFor]="menu"
              (click)="toggleDropdown()"
              style="
                float: right;
                margin-right: 5px;
                font-size: 14px;
                color: white;
                background-color: var(--color-teal);
              "
            >
             Action
              <div style="margin-left: 13px">
                <i
                  class="fas"
                  [ngClass]="isDropdownOpen ? 'fa-caret-down' : 'fa-caret-down'"
                  style="margin-right: px"
                ></i>
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="documentInfoMultiple()"
                *ngIf="!this.isReadOnly"
              
              >
              Get Document Info
              </button>
              <button mat-menu-item  (click)="importForms()">
                Import Forms From CPM Input
              </button>
              <button mat-menu-item     style="color: red;" (click)="clearAll()">
                Clear Forms From CPM Input
              </button>
              <button mat-menu-item   (click)="fetchGhostDraftCache()" *ngIf="!this.isReadOnly">
                Get Cache
              </button>
              <button mat-menu-item     (click)="checkExistingForms()">
                Check Forms
              </button>
              <button mat-menu-item     (click)="fileInput.click()"
              *ngIf="this.customFileCPM.length == 0">
              <input type="file" (change)="onFileSelected($event)" style="visibility: hidden; width:1px; height: 1px;" #fileInput>
              Upload CPM as File
              </button>
              <button mat-menu-item     style="color: red;"  (click)="resetLargeCPM()"
              *ngIf="this.customFileCPM.length > 0">
              Remove Uploaded CPM
              </button>
            </mat-menu>
            </div>
           
     
      </div> 
            </div>
          </div>
        </div>
        <div
          id="policy-form-content-container"
          class="col-md-12"
          *ngIf="!isFAST"
          style="margin-top: 20px"
        >
          <h6
            style="
              margin-bottom: 20px;
              border-bottom: 1px solid #ff4e00;
              display: inline-block;
            "
          >
            Package/Forms
          </h6>
          <div class="row">
            <div class="col-md-6">
              <ag-grid-angular
                class="ag-theme-balham fast_panel_table"
                style="
                height: calc(100vh - 341px) !important;
                overflow-x: hidden !important;
                border-radius: 7px;
                box-shadow: 0 0 7px #fff;
              
              "
                [gridOptions]="formsGridOptions"
              >
              </ag-grid-angular>
            </div>
            <div class="col-md-6">
              <ag-grid-angular
                class="ag-theme-balham fast_panel_table"
                style="
                height: calc(100vh - 341px) !important;
                overflow-x: hidden !important;
                border-radius: 7px;
                box-shadow: 0 0 7px #fff;
              
              "
                [gridOptions]="selectedFormsGridOptions"
              >
              </ag-grid-angular>
            </div>
          </div>
        </div>

        <div style="width: 100%; margin-top: 20px">
          <mat-tab-group [(selectedIndex)]="selectedIndex"
                         [disableRipple]="true"
                         class="test-harness-tab">
            <mat-tab label="CPM/CCM/GBS"
                     matTooltip="CommonPolicyModel/CommonClaimsModel/GuidewireBillingSchema">
              <ng-template matTabContent>
                <textarea rows="20"
                          cols="105"
                          (keyup)="onKey($event)"
                          [(ngModel)]="customCPM"
                          spellcheck="false"
                          (paste)="onPaste($event)"
                          class="mat-tab-textarea"></textarea>
              </ng-template>
            </mat-tab>
            <mat-tab label="Errors" *ngIf="!isFAST">
              <ng-template matTabContent>
                <textarea id="textAreaErr"
                          rows="20"
                          cols="105"
                          [(ngModel)]="Errors"
                          spellcheck="false"
                          class="mat-tab-textarea"></textarea>
              </ng-template>
            </mat-tab>
            <mat-tab label="API Request" *ngIf="!isFAST">
              <ng-template matTabContent>
                <textarea id="APIRequest"
                          rows="20"
                          cols="105"
                          [(ngModel)]="APIRequest"
                          spellcheck="false"
                          class="mat-tab-textarea"></textarea>
              </ng-template>
            </mat-tab>
            <mat-tab label="API Response" *ngIf="!isFAST">
              <ng-template matTabContent>
                <textarea id="textAreaRes"
                          rows="20"
                          cols="105"
                          [(ngModel)]="APIResponse"
                          spellcheck="false"
                          class="mat-tab-textarea"></textarea>
              </ng-template>
            </mat-tab>
            <mat-tab label="Performance" *ngIf="!isFAST">
              <ng-template matTabContent>
                <textarea id="textAreaPerf"
                          rows="20"
                          cols="105"
                          [(ngModel)]="Performance"
                          spellcheck="false"
                          class="mat-tab-textarea"></textarea>
              </ng-template>
            </mat-tab>
            <mat-tab label="Check Forms" *ngIf="!isFAST">
              <ng-template matTabContent>
                <textarea id="textAreaPerf"
                          rows="12"
                          cols="105"
                          [(ngModel)]="CheckForms"
                          spellcheck="false"
                          class="mat-tab-textarea"></textarea>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="container-log" style="display: -webkit-inline-box">
        <div
          style="
            display: grid;
            box-shadow: inset 0 0 20px #ccc;
            border-radius: 20px;
            padding: 20px;
            margin-top: 10px;
            margin-left: 10px;
            height: 250px;
            overflow: auto;
          "
          *ngIf="logSelected"
        >
          <div class="row" style="position: center">
            <b>Log Details</b>
          </div>
          <div class="row">
            <div id="execute-rules-content-heading-container" class="col-12">
              <label class="label_heading"
                >Deal Number: {{ this.dealNumber }}</label
              >
            </div>
          </div>
          <div class="row">
            <div id="execute-rules-content-heading-container" class="col-12">
              <label class="label_heading"
                >Action Type: {{ this.actionType }}</label
              >
            </div>
          </div>
          <div class="row">
            <div id="execute-rules-content-heading-container" class="col-12">
              <label class="label_heading"
                >Time Stamp: {{ this.timestamp }}</label
              >
            </div>
          </div>
        </div>
        <div
          style="
            display: grid;
            box-shadow: inset 0 0 20px #ccc;
            border-radius: 10px;
            padding: 20px;
            margin-top: 10px;
            margin-left: 10px;
            overflow: auto;
            height: 250px;
          "
          *ngIf="logSelected"
        >
          <div class="row" style="position: center">
            <b>Forms List</b>
          </div>
          <div class="row" *ngFor="let item of this.formsTxt">
            <div class="col-12" id="execute-rules-content-heading-container">
              <label class="label_heading">{{ item }}</label>
            </div>
          </div>
        </div>
        <div
          style="
            display: block;
            box-shadow: inset 0 0 20px #ccc;
            border-radius: 10px;
            padding: 20px;
            margin-top: 10px;
            margin-left: 10px;
            overflow: auto;
            height: 250px;
          "
          *ngIf="logSelected && gdfError"
        >
          <div class="row" style="position: center">
            <b style="width: 50%; margin-left: 100px; margin-right: 100px"
              >Error</b
            >
            <div
              class="row"
              style="overflow: auto; margin-left: 20px; height: 30%"
            >
              {{ this.gdfErrorText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="component-content-view"
    *ngIf="activeSection === 'Run Saved Tests'" style="height: calc(100vh - 117px);overflow-y: auto; overflow-x: hidden;"
  >
    <div class="container-fluid">
      <div class="col-md-12 pr-0">
        <div class="row">
          <div class="col-md-12" id="lob-content-container">
            <div
              class="heading-container fast_admin"
              id="policy-form-heading-container"
            >
              <label class="label_heading">Environment:</label>
              <div class="selectdiv select_wrapinline">
                <label>
                  <ng-select
                    class="custom-select"
                    [items]="envList"
                    bindLabel="Name"
                    bindValue="ID"
                    (change)="onEnvChange($event)"
                    [(ngModel)]="selectedEnv"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>
              <label class="label_heading" style="padding-left: 30px"
                >Test Groups:</label
              >
              <div class="selectdiv select_wrapinline">
                <label>
                  <ng-select
                    id="testGroups"
                    class="custom-select"
                    [items]="testGroups"
                    bindLabel="Name"
                    bindValue="id"
                    (change)="onTestCaseGridShow()"
                    [(ngModel)]="selectedTestGroup"
                  ></ng-select>
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }}">{{ item.Name }}</div>
                  </ng-template>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row pr-0" style="padding-top: 0px">
          <!-- Display test output -->
          <div class="col-md-12 pr-0">
            <div class="row" *ngIf="showTestCases" style="margin-bottom: 8px">
              <div class="heading-container fast_admin" style="margin-left: 10px;">
                <label class="label_heading">Test Cases</label>
              </div>
              <div id="test-case-container" class="col-12 pr-0">
                <ag-grid-angular
                  class="ag-theme-balham fast_panel_table"
                  [gridOptions]="testCaseGridOptions"
                  style="
                  height: calc(100vh - 341px) !important;
                  overflow-x: hidden !important;
                  border-radius: 7px;
                  box-shadow: 0 0 7px #fff;
                
                "
                 
                >
                </ag-grid-angular>
              </div>
            </div>
            <div class="row pr-0">
              <div class="col-12 pr-0" id="test-results-heading-container">
                <div class="heading-container fast_admin">
                  <label class="label_heading">Test Run Results</label>
                </div>
              </div>
              <div
                id="test-results-container"
                class="col-12 mrgbtm10 pr-0"
                style="overflow: visible; min-height: 100px"
              >
                <ag-grid-angular
                  class="ag-theme-balham fast_panel_table"
                  style="
                  height: calc(100vh - 341px) !important;
                  overflow-x: hidden !important;
                  border-radius: 7px;
                  box-shadow: 0 0 7px #fff;
                
                "
                  [gridOptions]="testResultsGridOptions"
                >
                </ag-grid-angular>
              </div>
            </div>
            <pre>{{ testOutput | json }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-testHarness-manageTests
    *ngIf="activeSection === 'Manage Tests'"
  ></app-testHarness-manageTests>
</div>
