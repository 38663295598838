<div class="detail-panel" *ngIf="showList">
    <div class="close-button-container">
      <button
        type="button"
        mat-raised-button
        class="btn-teal close-btn"
        (click)="onClose()"
      >
        X
      </button>
    </div>
  
    <div class="chip-container" *ngIf="displayedNames.length > 0">
      <div class="chip" *ngFor="let name of displayedNames">
        {{ name }}
        <button (click)="removeValue(name.split('-')[0])" class="remove-btn">
          ✖
        </button>
      </div>
    </div>
  </div>