<div class="component-content-view pr-0">
  <div class="container-fluid pr-0" style="height: calc(100vh - 117px); overflow-y: auto; overflow-x: hidden;">
    <div id="test-maintenance-container" class="row p-0">

      <div class="col-12 pr-0" id="test-group-heading-container">
        <div class="heading-container fast_admin">
          <label class="label_heading">Test Groups</label>
          <span class="policy-form-btn" id="test-group-add-btn" (click)="createTestGroup()">
            <i class="fas fa-plus" style="float: right;  margin-top:20px"></i>
          </span>
        </div>
      </div>


      <div id="test-group-container" class="col-12 pr-0">
        <ag-grid-angular class="ag-theme-balham fast_panel_table"
        style="
        height: calc(100vh - 341px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      
      "
                         [gridOptions]="testGroupGridOptions">
        </ag-grid-angular>
      </div>

      <div class="col-12 pr-0" id="test-case-heading-container">
        <div class="heading-container fast_admin">
          <label class="label_heading">Test Cases</label>
          <span class="policy-form-btn" id="test-case-add-btn" (click)="createTestCase()">
            <i class="fas fa-plus" style="float: right;  margin-top:20px"></i>
          </span>
        </div>
      </div>
      <div id="test-case-container" class="col-12 pr-0">
        <ag-grid-angular class="ag-theme-balham fast_panel_table"
        style="
        height: calc(100vh - 341px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      
      "
                         [gridOptions]="testCaseGridOptions" >
        </ag-grid-angular>
      </div>

    </div>
  </div>

</div>
