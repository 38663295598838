<div class="component-container" style="min-height: calc(100vh - 64px);">
  <!--Searchbar-->
  <div class="component-heading-container">
    <div class="col-md-12" style="margin-top: 5px">
      <div class="row">
        <div class="col-md-8 fast_admin" style="padding-left: 0px">
          <div
            @fadeInUp
            class="tw-border tw-rounded-full bg-foreground tw-py-2 ltr:tw-pl-6 rtl:tw-pr-6 ltr:tw-pr-3 rtl:tw-pl-3 text-secondary tw-max-w-xl tw-mx-auto tw-shadow-xl tw-flex tw-items-center"
            style="
              border-radius: calc(0.5 * var(48px));
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
              font-size: 16px;
              max-width: 100rem;
              margin-bottom: 13px;
              margin-left: 25px;
              height: 35px;
              background-color: white;
            "
          >
            <mat-icon class="tw-flex-none" svgIcon="mat:search"></mat-icon>
            <input
              class="tw-border-0 ltr:tw-ml-2 rtl:tw-mr-4 placeholder:text-secondary subheading-2 tw-outline-none tw-font-medium tw-w-0 bg-foreground tw-flex-auto"
              type="text"
              placeholder="Enter search form"
              aria-label="Number"
              matInput
              [formControl]="searchControl"
              [matAutocomplete]="auto"
              (keydown.enter)="selectFirstOption()"
              style="font: 17px var(--font); color: #424242"
            />
            <button type="button" class="clear-search-btn" (click)="clearSearch()">
              &times;
            </button>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event.option.value)"
            >
              <!-- Show filtered forms if search input is not empty -->
              <ng-container *ngIf="!isSearchInputEmpty">
                <mat-option
                  *ngFor="let option of filteredForms | async"
                  [value]="option.id"
                >
                  {{ option.InternalFormNumber }} - {{ option.FormName }}
                </mat-option>
              </ng-container>
              <!-- Show recent searches if search input is empty -->
              <ng-container *ngIf="isSearchInputEmpty">
                <mat-option
                  *ngFor="let recent of recentSearches"
                  [value]="recent.id"
                  (click)="onRecentSearchSelected(recent)"
                >
                  <mat-icon
                    class="tw-flex-none"
                    svgIcon="mat:history"
                  ></mat-icon>
                  {{ recent.InternalFormNumber }}-{{ recent.FormName }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </div>
        </div>
        <div
          class="col-md-3 btn_wrapper fast_admin"
          style="margin-left: auto; margin-right: 0px"
        >
          <div class="d-flex justify-content-end align-items-center">
            <!-- Mat-buttons -->
            <mat-button-toggle-group>
              <mat-button-toggle
                (click)="addForm()"
                *ngIf="activeSection === 'Attributes' && FormsWritePermission"
                >New Form</mat-button-toggle
              >
              <mat-button-toggle
                (click)="copyForm()"
                *ngIf="activeSection === 'Attributes' && FormsWritePermission"
                >Copy Form</mat-button-toggle
              >
              <mat-button-toggle
                (click)="addRuleSetup()"
                *ngIf="activeSection === 'Rules' && FormsWritePermission"
                >New Rule Setup</mat-button-toggle
              >
              <mat-button-toggle
                (click)="viewLOBCodes()"
                *ngIf="
                  activeSection === 'Rules' || activeSection === 'RulesEngine'
                "
                >View LOB Info</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
  <div class="mat-tab-header">
    <mat-tab-group
      headerPosition="above"
      [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="onTabChanged($event)"
    >
      <mat-tab label="Attributes" (click)="loadAttributes()"> </mat-tab>
      <mat-tab label="Rules Setup" (click)="loadRules()"></mat-tab>
      <mat-tab label="Notes" (click)="loadNotes()"></mat-tab>
      <mat-tab label="History" (click)="loadDeploymentHistory()"></mat-tab>
      <mat-tab label="Rules Engine" (click)="loadRulesEngine()"></mat-tab>
    </mat-tab-group>
  </div>

    <!-- End Heading section -->
    <app-form-page-attributes-tab
      *ngIf="activeSection === 'Attributes'"
      [currentForm]="currentForm"
      [isNewForm]="isNewForm"
      (saveForm)="onSaveForm($event)"
      (discardChanges)="onDiscardChanges()"
    ></app-form-page-attributes-tab>

    <app-form-page-rules-tab
      *ngIf="activeSection === 'Rules'"
      [currentForm]="currentForm"
      [lobList]="lobList"
      (saveForm)="onSaveForm($event)"
    ></app-form-page-rules-tab>

    <app-form-page-history-tab
      *ngIf="activeSection === 'History'"
      [currentForm]="currentForm"
      [formHistory]="formHistory"
    ></app-form-page-history-tab>

    <app-form-page-notes-tab
      *ngIf="activeSection === 'Notes'"
      [currentForm]="currentForm"
      [formHistory]="formHistory"
    ></app-form-page-notes-tab>

    <app-form-page-rules-engine
      *ngIf="activeSection === 'RulesEngine'"
      [currentForm]="currentForm"
    ></app-form-page-rules-engine>
  </div>
</div>
