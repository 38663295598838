//Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

//Third Party Imports
import { NgbModule, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

//Internal Imports
import {
  FormPageContainerComponent,
  FormPageAttributesTabComponent,
  FormPageAttributesContainerComponent,
  FormPageRulesTabComponent,
  FormPageRuleSetupComponent,
  FormPageRuleSetupListComponent,
  FormPageHistoryTabComponent,
  FormPageNotesTabComponent,
  FormPageAddRuleSetupComponent,
} from "./components";
import { FormPageRuleComponent } from "./components/form-page-rule/form-page-rule.component";
import { FormPageExpressionComponent } from "./components/form-page-expression/form-page-expression.component";
import { FormPageRoutingModule } from "./form-page.routing";
//import { NoteService } from './services';
import {
  FrameworkModule,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
} from "../framework";
import { UserNotesModule } from "../user-notes";
import { FormPageRulesEngine } from "../form-page/components/form-page-rules-engine/form-page-rules-engine.component";
import { FormPageProductDefinition } from "../form-page/components/form-page-product-def/form-page-product-def";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormPageDetailviewComponent } from './components/form-page-detailview/form-page-detailview.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

/**
  Demo Module 
*/
@NgModule({
  declarations: [
    FormPageContainerComponent,
    FormPageAttributesTabComponent,
    FormPageAttributesContainerComponent,
    FormPageRulesTabComponent,
    FormPageRuleSetupComponent,
    FormPageRuleSetupListComponent,
    FormPageHistoryTabComponent,
    FormPageNotesTabComponent,
    FormPageRuleComponent,
    FormPageExpressionComponent,
    FormPageAddRuleSetupComponent,
    FormPageRulesEngine,
    FormPageProductDefinition,
    FormPageDetailviewComponent,
    ConfirmationDialogComponent,
   ],
  imports: [
    FormPageRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    FrameworkModule,
    UserNotesModule,
    AgGridModule.withComponents([DateFilterComponent, BooleanFilterComponent]),
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  exports: [FormPageRuleComponent],
})
export class FormPageModule {}
