<div class="component-container">
  <div class="component-heading-container  d-flex align-items-center justify-content-between">
    <h3 class="component-heading-text">Publish Forms</h3>
    <div class="btn_wrapper">
      <button
        mat-raised-button
        class="btn-teal mrgrgt10"
        type="button"
        (click)="updatePublish()"
      >
        Save
      </button>
    </div>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row  d-flex align-items-center justify-content-between">
        <div class="col-md-4">
          <div
            class="heading-container fast_admin"
            id="policy-form-heading-container"
          >
            <label class="label_heading">Line Of Business:</label>
            <div class="selectdiv select_wrapinline1">
              <label>
                <ng-select
                  id="lineOfBusiness"
                  (change)="onLOBChange($event)"
                  class="custom-select"
                  [items]="lineOfBusiness"
                  bindLabel="Description"
                  bindValue="Name"
                  [(ngModel)]="selectedLOB"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Name }} - {{ item.Description }}">
                      {{ item.Name }} - {{ item.Description }}
                    </div>
                  </ng-template>
                </ng-select>
              </label>
            </div>

          </div>
        </div>
        <div  style="font-size: 14px; "
      *ngIf="selectedRowCount > 0" class="selected-forms-count">
          Number  of Selected Forms:  <span class="highlight-count">{{ selectedRowCount }}</span>
      </div>
      </div>

      <!--<div class="btn_wrapper">
        <button mat-raised-button class="btn-teal" type="button" (click)="externalFilterChanged(interalFormNumber)">Search</button>
      </div>-->
      <div class="row" id="grid_container" style="margin-left: 2px;">
        <div
          id="policy-form-content-container"
          class="col-12  pd-0" 
          style="overflow: visible; min-height: 100px"
        >
          <ag-grid-angular
            class="ag-theme-balham fast_panel_table"
            style="
        height: calc(100vh - 214px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      
      "
            [pinnedTopRowData]="pinnedTopRowData"
            [gridOptions]="formsGridOptions"
            (selectionChanged)="onSelectionChanged($event)"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
