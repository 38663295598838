//Angular Imports 
import { Component, HostBinding } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe, SharedService, EnvironmentService, DateTimeUtcPipe, ReportService
} from '../../../../../framework';
import { ExecutionService, TestingService } from '../../services';
import { EXECUTION_ENGINE_LIST } from '../../configs';


import 'rxjs';
import { saveAs } from 'file-saver';

import { RuleEngineResult, RuleEngineLog, TestExecution, TestGroup, TestRun, FormResult, TestCaseResult } from '../../models';
import { TestCase } from '../../models/test-case.model';
import { forkJoin } from 'rxjs';
import { ExecuteResultsContainerComponent } from '../exec-rules-results-container/exec-results-container.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LogSearch, TestHarnessLogs } from '../../../../../logs/components';
import { TestHarnessLog } from '../../../../testSuites/testHarness/models/testHarness-log.model';
import * as xml2js from 'xml2js';
import { FailedTestDetails } from '../failed-test-details/failed-test-details.component';
import { TestCaseDetail } from '..';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { MatTabChangeEvent } from '@angular/material/tabs';



/**
 * Forms Component
*/
@Component({
  selector: 'app-execute-rules',
  templateUrl: './exec-rules.component.html',
  styleUrls: ['./exec-rules.component.scss']
})

export class ExecuteRulesComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  initialRowDataLoad$;

  //Contains global LOB list for descriptions
  public _lobList: any[];

  // Displays results from unit test or from log entry
  unitTestResultsGridOptions: GridOptions;


  // Displays results from an automated test run
  testResultsGridOptions: GridOptions;

  executeRulesGridOptions: GridOptions;

  testCasesGridOptions: GridOptions;

  // Used to populate environment list on Unit Test and Run Test Groups tabs
  envList: any[];

  // Holds the environment name selected from the environment list on the Unit Test or Run Test Groups tab
  selectedEnv: string;

  // Holds CPM xml entered on Unit Test tab
  policyXML: string;

  // Used to populate the test group list on the Run Test Groups tab
  testGroups: TestGroup[];

  // Holds the test group id of the selected test group from the test groups list on the Run Test Groups tab
  selectedTestGroup: TestGroup;

   //When selecting a test group this holds the associated test cases
  selectedTestCases: TestCase[];

  //Selected test cases on the grid
  rowsSelected: TestCase[];

  // Holds the results of a call to getForms on the Unit Test tab
  ruleEngineResults: RuleEngineResult[];

  // Populates the Form Number field on the Unit Test tab
  public formNumber: string;

  // Determines whether the detailed rule engine results should be shown at the bottom of the Unit Test tab
  public showResults: boolean;

  // Populates the Report Query field on the Reporting tab
  // DB query string that will be executed to generate a report
  public reportQuery: string;

  // Results from clicking the Run Tests button
  public displayTestOutput: boolean;
  public testOutput: any;
  testExecutionResults: TestExecution[];

  public formResults: FormResult[] = [];

  // determines which component (tab) is shown in the page
  // 'Unit Test', 'Run Test Groups', 'Test Maintenance', 'Reporting'
  public activeSection;

  public policyEffectiveDate: string;

  public isFAST: boolean = true;

  //For GDF logs display the deal number, action type, and timestamp
  public dealNumber: string = "";
  public actionType: string = "";
  public timeStamp = "";
  public policyFormList: string[] = [];
  //Used in HTML to determine if test case list should be displayed
  public showTestCases: boolean = false;
  
  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService, 
    private executionService: ExecutionService,
    private testingService: TestingService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private reportService: ReportService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService,
    private router: Router) {
    super(_appInsightsService);
    this.width = "100%";
    this.envList = EXECUTION_ENGINE_LIST;
    this.selectedEnv = this.envList[0].Name;
    this.showResults = false;
    this.displayTestOutput = false;
    this.testExecutionResults = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    // Configure grid
    this.configureUnitTestResultsGrid();
    this.getTestGroups();
    this.configureTestResultsGrid();
    this.loadUnitTest();
    this.configureDetailsGrid();
    this.configureTestCasesGrid();

    if (this.sharedService.globalTestResults) {
      this.testExecutionResults = this.sharedService.globalTestResults;
      this.displayTestResults();
    }
    this.sharedService.getMasterData().subscribe(res => {
      this._lobList = res.LineOfBusinessList;
    });
    //let testExecutionBtn = document.getElementById("test_execution_section_btn");
    //testExecutionBtn.click();
    //testExecutionBtn.style.borderColor = 'blue';

    // Initialize selected environment to the current environment
    this.selectedEnv = this.environmentService.environmentDetails.Environment;

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  //Controls which section to load, changing this variable will change which elements are loaded from the HTML page
  loadUnitTest() {
    this.activeSection = 'Unit Test';
  }

  loadRunTestGroups() {
    this.activeSection = "Run Test Groups"
  }

  loadTestMaintenance() {
    this.activeSection = 'Test Maintenance';
  }

  loadReporting() {
    this.activeSection = 'Reporting';
  }

  // onSelectSystem(system:string) {
  //   if (system == 'GDF') {
  //     this.isFAST = false;
  //   }
  //   else if (system == 'FAST') {
  //     this.isFAST = true;
  //   }
  // }
  onSelectSystem(event: MatTabChangeEvent) {
    const selectedIndex = event.index;
    if (selectedIndex === 1) {
      this.isFAST = false;
    } else if (selectedIndex === 0) {
      this.isFAST = true;
    }
  }

  //Get all test groups from the DB
  getTestGroups(): void {
    this.testingService.getTestGroups().subscribe(res => {
      console.log(res);
      this.testGroups = res;
    },
    err => {
      this.toastr.error('Error while fetching test groups.', 'Error');
    });
  }

  showTestCasesHandler(): void {
    this.selectedTestCases = [];
    /*for(var i = 0; i < this.allTestCases.length; i++){
      console.log(this.selectedTestGroup);
      console.log(this.allTestCases[i].TestGroups);
      if (this.allTestCases[i].TestGroups.indexOf(this.selectedTestGroup.Id) != -1) {
        this.selectedTestCases.push(this.allTestCases[i]);
      }
    }*/
    this.testingService.getTestCasesByTestGroupId(this.selectedTestGroup.Id).subscribe(res => {
      console.log(res);
      this.selectedTestCases = res;
      this.testCasesGridOptions.api.setRowData(this.selectedTestCases);
    });

    this.showTestCases = true;
  }

  // Runs a query for reporting
  runQuery(db: string): void {

    let collectionName = '';  //'RuleSetAndRules';
    let query = this.reportQuery;

    // convert newlines to spaces in the query string
    query = query.replace(/\n/g, " ");

    this.reportService.getToken(this.selectedEnv).subscribe(res => {
      if (res) {
        //Fetch rules result 
        this.reportService.RunReport(collectionName, query, db, this.selectedEnv).subscribe(res => {
          const items = res;

          // Iterate through each property of each object and attempt to convert any
          // property with "date" in the name from unixtimestamp to a date value.
          items.forEach(function (item, index) {
            Object.keys(item).forEach(function (key, index) {
              if (key.toUpperCase().includes("DATE")) {
                try {
                  if (item[key] !== null) {
                    let date = new Date(item[key] * 1000);
                    item[key] = date.toLocaleDateString() + " " + date.toLocaleTimeString();
                  }   
                } catch (e) {

                }
              }
            });
          });

          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(items[0]);
          let csv: string[] = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvFullString = csv.join('\r\n');

          //console.log(csv)

          var file = new File([csvFullString], "FastReport.csv", { type: "text/plain;charset=utf-8" });
          saveAs(file);
        },
        err => {
          this.toastr.error('Error while running report', 'Error');
        }); // End RunReport block
      }
      else {
        this.toastr.error('Error while fetching token', 'Error');
      }

    });
  }

  //Public Methods


  // Send request to FAST API to get forms and also process testing results
  getForms(): void {
    this.showResults = false;
    this.unitTestResultsGridOptions.columnApi.setColumnVisible('RiskLevels', false);
    this.executeRulesGridOptions.columnApi.setColumnVisible('RiskLevelID', false);
    if (this.policyXML && this.selectedEnv) {
      //Call testing service which will make normal request to FAST to get forms as well as provide a detailed breakdown of rule execution
      console.log(this.policyXML);
      this.testingService.getPolicyFormsPrototype(this.policyXML, this.selectedEnv).subscribe(res => {
        if (res) {
          this.showResults = true;
          this.ruleEngineResults = res;
          //this.policyXML = JSON.stringify(res);
          this.formResults = this.ruleEngineResults[0].DiagnosticResults;
          //If at least one risk level is populated display risk level column
          if (this.formResults.some(x => x.RiskLevelID != null && x.RiskLevelID != "")) {
            this.executeRulesGridOptions.columnApi.setColumnVisible('RiskLevelID', true);
            this.unitTestResultsGridOptions.columnApi.setColumnVisible('RiskLevels', true);
          }
          this.executeRulesGridOptions.api.sizeColumnsToFit();
          this.unitTestResultsGridOptions.api.sizeColumnsToFit();
          this.executeRulesGridOptions.api.setRowData(this.formResults);
          this.policyEffectiveDate = this.dateTimePipe.transform(this.formResults[0].DateResult.PolicyEffectiveDate).toString();
        }
        console.log("RES");
        console.log(res);
        let combinedFinalResults: any[] = [];
        for (let r of res) {
          r.Scenario = "Manual Input";
          combinedFinalResults = combinedFinalResults.concat(r.FinalResults);
        }

        // PB - No longer using global results for unit tests, only using it for the automated tests from Run Test Groups tab
        //this.executionService.addToGlobalTestResults(res);

        // Sort By Mandatory 
        combinedFinalResults.sort((a, b) => {
          if (a.AttachmentReason == b.AttachmentReason) {
            return 0;
          }
          else if (a.AttachmentReason == "Mandatory") {
            return -1;
          }
          else {
            return 1;
          }
        });

        //Populate final results grid
        this.unitTestResultsGridOptions.api.setRowData(combinedFinalResults);

      },
        err => {
          this.toastr.error('Error while fetching forms', 'Error');
        });

    }
  }

  private configureTestCasesGrid(): void {
    this.testCasesGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: 'normal',
      columnDefs: this.createTestCaseColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testCasesGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.testCasesGridOptions.api.sizeColumnsToFit();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onTestCaseRowClicked(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onSelectionChanged: (event) => {
          this.onSelectionChanged();
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }
  

  private configureUnitTestResultsGrid(): void {
    this.initialRowDataLoad$ = [];
    this.unitTestResultsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createUnitTestResultsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 50,
      enableColResize: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.unitTestResultsGridOptions.api.setRowData([]);
        this.unitTestResultsGridOptions.columnApi.setColumnVisible('RiskLevels', false);
        this.unitTestResultsGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  onSelectionChanged() {
      this.rowsSelected = this.testCasesGridOptions.api.getSelectedRows();
    console.log(this.rowsSelected);
  }

  private createTestCaseColumnDef(): any[] {
    return [
         { headerName:'', checkboxSelection:true, field: 'RowSelect', tooltipValueGetter: (params) => params.value,suppressMenu: true, suppressSorting: true, width: 20, filter:'booleanFilterComponent'},
         { headerName: 'Name', field: 'Name', tooltipValueGetter: (params) => params.value,comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
         { headerName: 'Description',tooltipValueGetter: (params) => params.value, field: 'Description'},
         {
          headerName: "View Test Case Details",
          width:70,
          suppressMenu: true,
          suppressSorting: true,tooltipValueGetter: (params) => params.value,
          cellRenderer: function (params) {
              return `<img src="/assets/images/View_Doc.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
        }
          
      }
   
      ]
  }
 
  private createUnitTestResultsColumDef(): any[] {
    return [
      {
        headerName: 'Mandatory', field: 'AttachmentReason',   headerTooltip: "Mandatory",tooltipValueGetter: (params) => params.value, suppressFilter:true, width: 100, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == "Mandatory" ? 'checked' : ''} disabled/>`;
        } },
      { headerName: 'External Form Number',   headerTooltip: "External Form Number",field: 'ExternalFormNumber', tooltipValueGetter: (params) => params.value,resizable: true, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Form Name', field: 'FormName',  headerTooltip: "Form Name", resizable: true, tooltipValueGetter: (params) => params.value,filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Form Type', field: 'FormType',  headerTooltip: "Form Type", filter: 'agTextColumnFilter',tooltipValueGetter: (params) => params.value, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Internal Form Number', field: 'InternalFormNumber',   headerTooltip: "Internal Form Number",resizable: true,tooltipValueGetter: (params) => params.value, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Frequently Used',   headerTooltip: "Frequently Used",tooltipValueGetter: (params) => params.value, field: 'FrequentlyUsedIndicatorSpecified', width: 100 },
      {
        headerName: 'LOB', field: 'LineOfBusinessID',   headerTooltip: "LOB",tooltipValueGetter: (params) => params.value,filter: 'agTextColumnFilter', resizable: true, cellRenderer: params => {
          let index = _.findIndex(this._lobList, { Name: params.value });
          return this._lobList[index].Description;
        }
      },
      { headerName: 'Sort Order', field: 'SortOrder',  headerTooltip: "Sort Order", tooltipValueGetter: (params) => params.value,filter: 'agNumberColumnFilter', width: 100, resizable: true },
      {
        headerName: 'Risk Levels', field: 'RiskLevels',   headerTooltip: "Risk Levels",tooltipValueGetter: (params) => params.value,filter: 'agTextColumnFilter', resizable: true, sortable: true,
        valueGetter: function (params) {
          let risks = params.data.RiskLevels;
          return risks.join(", ");
        }
        }
    ]
  }

  private configureTestResultsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testResultsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createTestResultsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testResultsGridOptions.api.setRowData(this.testExecutionResults);
        //this.testResultsGridOptions.api.setRowData([]);
        this.testResultsGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onTestResultsClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  private createTestResultsColumDef(): any[] {
    return [
      { headerName: 'TestCase Name',  headerTooltip:"TestCase Name",field: 'TestCaseName',tooltipValueGetter: (params) => params.value, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Result', field: 'OverallResult', headerTooltip:"Result",tooltipValueGetter: (params) => params.value, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'RunTime', field: 'RunTime', headerTooltip:"RunTime",tooltipValueGetter: (params) => params.value, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: "View Details of Failures",
        width:70,
        suppressMenu: true,
        headerTooltip:"View Details of Failures",
        suppressSorting: true,
        tooltipValueGetter: (params) => params.value,
        cellRenderer: function (params) {
          if (params.data.OverallResult == "Fail" || params.data.hasExtras) {
            return `<img src="/assets/images/View_Doc.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          }
          return "";
        }
          
      }
    ]
  }

  private onTestResultsClick(event: any): void {
    if (event.event.target !== undefined) {
      let data = event.data;
      let actionType = event.event.target.getAttribute("data-action-type");
      console.log(data);
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(FailedTestDetails, { windowClass: 'xl' });
          modalRef.componentInstance.testCaseName = data.TestCaseName;
          modalRef.componentInstance.executionResults = data.ExecutionResults;
          modalRef.result.then((result: any) => {
            if (result == "Update") {
              if (confirm("This will update the test case so the extra/missing forms will sync with the expected results, continue?")) {
                this.updateFailedTestCase(data.TestCase, data.ExecutionResults);
              }
            }
          });
          
      }

    }
  }

  private updateFailedTestCase(failedTestCase: TestCase, executionResults: TestCaseResult[]) {
    //Update failing test case with failed results to turn them into expected results
    //Iterate through all results of the test case (can be more than one if different quote options/LOBs)
    executionResults.forEach(result => {
      //For each test result determine which forms are missing and extra compared to the test case, and update its expected results with the missing/extra form
      for (var i = 0; i < result.MissingMandatory.length; i++) {
        for (var j = 0; j < failedTestCase.ExpectedResults.length; j++) {
          if (failedTestCase.ExpectedResults[j].MandatoryForms.includes(result.MissingMandatory[i]) && failedTestCase.ExpectedResults[j].LOB == result.LOB) {
            failedTestCase.ExpectedResults[j].MandatoryForms = failedTestCase.ExpectedResults[j].MandatoryForms.filter( item => item !== result.MissingMandatory[i]);
          }
        }
      }
      for (var i = 0; i < result.MissingOptional.length; i++) {
        for (var j = 0; j < failedTestCase.ExpectedResults.length; j++) {
          if (failedTestCase.ExpectedResults[j].OptionalForms.includes(result.MissingOptional[i]) && failedTestCase.ExpectedResults[j].LOB == result.LOB) {
            failedTestCase.ExpectedResults[j].OptionalForms = failedTestCase.ExpectedResults[j].OptionalForms.filter(item => item !== result.MissingOptional[i]);
          }
        }
      }
      for (var i = 0; i < result.ExtraMandatory.length; i++) {
        for (var j = 0; j < failedTestCase.ExpectedResults.length; j++) {
          if (!failedTestCase.ExpectedResults[j].MandatoryForms.includes(result.ExtraMandatory[i]) && failedTestCase.ExpectedResults[j].LOB == result.LOB) {
            failedTestCase.ExpectedResults[j].MandatoryForms.push(result.ExtraMandatory[i]);
          }
        }
      }
      for (var i = 0; i < result.ExtraOptional.length; i++) {
        for (var j = 0; j < failedTestCase.ExpectedResults.length; j++) {
          if (!failedTestCase.ExpectedResults[j].OptionalForms.includes(result.ExtraOptional[i]) && failedTestCase.ExpectedResults[j].LOB == result.LOB) {
            failedTestCase.ExpectedResults[j].OptionalForms.push(result.ExtraOptional[i]);
          }
        }
      }

    });
    this.executionService.updateTestCase(failedTestCase).subscribe(res => {
      console.log(res);
    })
      
  }

  private onTestCaseRowClicked(event: any) {
    if (event.event.target !== undefined) {
      let data = event.data;
      console.log(data);
      this.testingService.getTestCaseById(data.Id).subscribe(res => {
        const modalRef = this._modal.open(TestCaseDetail, { windowClass: 'test-case-detail-modal' });
        modalRef.componentInstance.testCase = res;
        modalRef.componentInstance.actionType = "Read";

    });

    }
  }

  private onResultsRowClick(event: any): void {
    if (event.event.target !== undefined) {
      this.formNumber = event.data.InternalFormNumber;
    }
  }


    //const modalRef = this._modal.open(ExecResultsFormView, { windowClass: 'test-result-form-view-modal' });


  //Open Log Search Window and process selected log if provided
  searchLogs(): void {
    if (this.isFAST) {
        this.unitTestResultsGridOptions.columnApi.setColumnVisible('RiskLevels', false);
        this.executeRulesGridOptions.columnApi.setColumnVisible('RiskLevelID', false);
        const modalRef = this._modal.open(LogSearch, { backdrop: 'static', size: 'lg' });

        modalRef.result.then((result: RuleEngineLog) => {
        if (result) {
          console.log(result);
          let ruleEngineResults: RuleEngineResult[] = JSON.parse(result.RuleEngineResults);
          this.ruleEngineResults = ruleEngineResults;
          this.showResults = true;
          this.policyXML = result.CPM;
          this.formResults = this.ruleEngineResults[0].DiagnosticResults;
          this.executeRulesGridOptions.api.setRowData(this.formResults);
          //If at least one risk level is populated on a form make the columns visible
          if (this.formResults.some(x => x.RiskLevelID != null && x.RiskLevelID != "")) {
            this.executeRulesGridOptions.columnApi.setColumnVisible('RiskLevelID', true);
            this.unitTestResultsGridOptions.columnApi.setColumnVisible('RiskLevels', true);
          }
          this.executeRulesGridOptions.api.sizeColumnsToFit();
          this.unitTestResultsGridOptions.api.sizeColumnsToFit();
          this.policyEffectiveDate = this.dateTimePipe.transform(this.formResults[0].DateResult.PolicyEffectiveDate).toString();

          let combinedFinalResults: any[] = [];
          for (let r of ruleEngineResults) {
            r.Scenario = "Manual Input";
            combinedFinalResults = combinedFinalResults.concat(r.FinalResults);
          }

          // PB - changing this to use the locally stored ruleEngineResults rather than the globalTestResults in the SharedService.
          //this.executionService.addToGlobalTestResults(ruleEngineResults);

          // Sort By Mandatory 
          combinedFinalResults.sort((a, b) => {
            if (a.AttachmentReason == b.AttachmentReason) {
              return 0;
            }
            else if (a.AttachmentReason == "Mandatory") {
              return -1;
            }
            else {
              return 1;
            }
          });

          //Populate final results grid
          console.log(combinedFinalResults);
          this.unitTestResultsGridOptions.api.setRowData(combinedFinalResults);

        }
      });
    }
    else{
      if(this.selectedEnv != "" && this.selectedEnv != null){
        const modalRef = this._modal.open(TestHarnessLogs, { backdrop: 'static', size: 'lg' });
        modalRef.result.then((result: TestHarnessLog) => {
        if (result) {
          console.log(result);
          this.policyFormList = [];
          this.policyXML = result.XmlData;
          var date = Date.parse(result.TimeStamp);
          this.timeStamp = new Date(date).toString();
          this.actionType = result.ActionType;
          this.processCPM();
          this.policyFormList = result.Forms;
          
          }
        });
    }
    }
    
  }

  loadLog(result: RuleEngineLog) {
    console.log(result);
    let ruleEngineResults: RuleEngineResult[] = JSON.parse(result.RuleEngineResults);
    this.ruleEngineResults = ruleEngineResults;
    this.showResults = true;
    this.policyXML = result.CPM;
    this.formResults = this.ruleEngineResults[0].DiagnosticResults;
    this.executeRulesGridOptions.api.setRowData(this.formResults);
    //If at least one risk level is populated on a form make the columns visible
    if (this.formResults.some(x => x.RiskLevelID != null && x.RiskLevelID != "")) {
      this.executeRulesGridOptions.columnApi.setColumnVisible('RiskLevelID', true);
      this.unitTestResultsGridOptions.columnApi.setColumnVisible('RiskLevels', true);
    }
    this.executeRulesGridOptions.api.sizeColumnsToFit();
    this.unitTestResultsGridOptions.api.sizeColumnsToFit();
    this.policyEffectiveDate = this.dateTimePipe.transform(this.formResults[0].DateResult.PolicyEffectiveDate).toString();

    let combinedFinalResults: any[] = [];
    for (let r of ruleEngineResults) {
      r.Scenario = "Manual Input";
      combinedFinalResults = combinedFinalResults.concat(r.FinalResults);
    }

    // PB - changing this to use the locally stored ruleEngineResults rather than the globalTestResults in the SharedService.
    //this.executionService.addToGlobalTestResults(ruleEngineResults);

    // Sort By Mandatory 
    combinedFinalResults.sort((a, b) => {
      if (a.AttachmentReason == b.AttachmentReason) {
        return 0;
      }
      else if (a.AttachmentReason == "Mandatory") {
        return -1;
      }
      else {
        return 1;
      }
    });

    //Populate final results grid
    console.log(combinedFinalResults);
    this.unitTestResultsGridOptions.api.setRowData(combinedFinalResults);
  }

  processCPM() {
    //If CPM Input exists initialize temp form list and parser to parse XML to JSON
    if(this.policyXML != null && this.policyXML.length > 1){
      var parser = new xml2js.Parser;
      var dealNumber: string = "";
        parser.parseString(this.policyXML, function (err, result) {
              //Pull forms list into array var
            if (result.QuoteMessage != null) {
              console.log(result);
              dealNumber = String(result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0].Policy[0].SubmissionNumber);
            }
            else {
              dealNumber = String(result.PolicyMessage.Policy[0].SubmissionNumber);
            }   
        });

      this.dealNumber = dealNumber;

    }
  }

  runTests(): void {
    this.sharedService.globalTestRunStatus = "Test Run In Progress";

    let testRun: TestRun = {
      "Environment": this.selectedEnv,
      "TestGroupIds": [this.selectedTestGroup.Id],
      "TestCases": null
    }
    console.log(testRun);
    this.testingService.executeTestRun(testRun).subscribe(res => {
      this.sharedService.incrementCallTracker();

      this.sharedService.globalTestResults = res;
      this.testExecutionResults = res;

      this.sharedService.globalTestRunStatus = "Test Run Complete";
      this.toastr.success(`TestGroup executed successfully.`);

      this.displayTestResults();
    },
      err => {
        this.toastr.error('Error while executing tests.', 'Error');
    });

  }

  runTestCase(): void {
     this.sharedService.globalTestRunStatus = "Test Run In Progress";

    let testRun: TestRun = {
      "Environment": this.selectedEnv,
      "TestGroupIds": [this.selectedTestGroup.Id],
      "TestCases": this.rowsSelected
    }
    this.testingService.executeTestRun(testRun).subscribe(res => {
      this.sharedService.incrementCallTracker();

      this.sharedService.globalTestResults = res;
      this.testExecutionResults = res;

      this.sharedService.globalTestRunStatus = "Test Run Complete";
      this.toastr.success(`TestGroup executed successfully.`);

      this.displayTestResults();
    },
      err => {
        this.toastr.error('Error while executing tests.', 'Error');
    });
  }

  displayTestResults(): void {
    //let executionResults: TestExecution[] = this.sharedService.globalTestResults;
    let displayResults: any[] = [];

    for (let execution of this.testExecutionResults) {
      if (execution.OverallResult == "Pass") {
        for (var i = 0; i < execution.ExecutionResults.length; i++) {
          if (execution.ExecutionResults[i].ExtraOptional.length > 0) {
            execution.hasExtras = true;
          }
        }
      }
      let displayResult = { "TestName": execution.TestCaseName, "Result": execution.OverallResult, "DetailedResults": execution.ExecutionResults };
      displayResults.push(displayResult);
    }

    this.displayTestOutput = true;
    this.testOutput = displayResults;

    

    if (this.testResultsGridOptions.api) {
      //this.testResultsGridOptions.api.setRowData(executionResults);
      this.testResultsGridOptions.api.setRowData(this.testExecutionResults);
    }
  }

  private configureDetailsGrid(): void {
    this.initialRowDataLoad$ = [];
    this.executeRulesGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 50,
      enableColResize: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.executeRulesGridOptions.api.setRowData([]);
        this.executeRulesGridOptions.columnApi.setColumnVisible('RiskLevelID', false);
        this.executeRulesGridOptions.api.sizeColumnsToFit();
        if (!isNullOrUndefined(window.history.state.log)) {

          this.loadLog(window.history.state.log);
        }
      },
      onRowClicked: (event) => {
        this.onDetailsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  onDetailsRowClick(e) {
     if (e.event.target !== undefined) {
          let data = e.data;
          let actionType = e.event.target.getAttribute("data-action-type");
          switch (actionType) {
            case "Rules":
              const modalRef = this._modal.open(ExecuteResultsContainerComponent, {windowClass: 'xl'});
              modalRef.componentInstance.ruleResults = data.RuleResults;
          }

        }
  } 

  createColumnDef(): any[] {
    return [
      { headerNmae: 'Attached', field: 'Result',  headerTooltip: "Attached ", tooltipValueGetter: (params) => params.value, filter: 'agTextColumnFilter', width:70 },
      { headerName: 'Internal Form Number',  headerTooltip: "Internal Form Number",tooltipValueGetter: (params) => params.value, resizable: true, field: 'Form.InternalFormNumber', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Sort Order',  headerTooltip: "Sort Order",tooltipValueGetter: (params) => params.value, field: 'Form.SortOrder', filter: 'agNumberColumnFilter', width: 100 },
      {
        headerName: 'Effective Date',  headerTooltip: "Effective Date", tooltipValueGetter: (params) => params.value,field: 'Form.EffectiveDate', filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      {
        headerName: 'Expiration Date',   headerTooltip: "Expiration Date",tooltipValueGetter: (params) => params.value,field: 'Form.ExpirationDate', filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      {
        headerName: 'Submission Expiration Date',  headerTooltip: "Submission Expiration Date",tooltipValueGetter: (params) => params.value, field: 'Form.SubmissionExpirationDate', filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      { headerName: 'Date Result',  headerTooltip: "Date Result", tooltipValueGetter: (params) => params.value,field: 'DateResult.Result', filter:'agTextColumnFilter', resizable: true },
      { headerName: 'Forced Optional',  headerTooltip: "Forced Optional", tooltipValueGetter: (params) => params.value,field: 'DateResult.ForceOptional', filter: 'agTextColumnFilter', resizable: true },
      { headerName: 'Risk Level',   headerTooltip: "Risk Level",tooltipValueGetter: (params) => params.value,field: 'RiskLevelID', filter: 'agTextColumnFilter', resizable:true, sortable: true},
      {
        headerName: "Actions",  headerTooltip: "Actions",
        width:130,
        suppressMenu: true,
        tooltipValueGetter: (params) => params.value,
        suppressSorting: true,
        filter:'booleanFilterComponent',
        template:
          `
         <img src="/assets/images/preview_icon.png" data-action-type="Rules" class="cursor_pointer" title="Rules">
           `
      }
    ]
  }

  clearTestOutput(): void {
    this.testOutput = null;
    this.sharedService.globalTestRunStatus = null;
    this.sharedService.globalTestRunResultId = null;
    this.sharedService.globalTestResults = null;
  }

  selectedTabIndex: number = 0;
  onTabChanged(index: number) {
    this.selectedTabIndex = index;
    switch (index) {
      case 0:
        this.loadUnitTest();
        break;
      case 1:
        this.loadRunTestGroups();
        break;
      case 2:
        this.loadTestMaintenance();
        break;
      case 3:
        this.loadReporting();
        break;
      default:
        break;
    }
  }
  
  isDocumentPanelOpen = false;
  sharedDocuments = [
    {
      name: "How to Use Test Suite",
      url:
    "https://confluence.markelcorp.com/pages/viewpage.action?pageId=145463027"
       
    },
  ];

 toggleDocumentPanel(event: Event): void {
    this.isDocumentPanelOpen = !this.isDocumentPanelOpen;
    event.stopPropagation();
  }

  
}
