//Angular Imports 
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { AdminForm } from "../../form-page/models/adminForm.model";

//Internal Imports
import { PolicyForm } from "../models";

/**
  * Policy Forms Provider
*/
@Injectable({
  providedIn: 'root'
})
export class PolicyFormsService {
  
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }


  
  /**
  * Get form by internal form number
  * @param policyForm
  */
  GetFormsByFormNumber(internalFormNo: string): Observable<PolicyForm[]> {
    let url = `api/FormsMaster/Form?formNumber=` + internalFormNo;
    return this.http.get<PolicyForm[]>(url).pipe(
      map(res => res)
    );
  }

  formExists(internalFormNo: string): Observable<boolean> {
    let url = `api/FormsMaster/ValidateFormExists?formNumber=` + internalFormNo;
    return this.http.get<boolean>(url).pipe(
      map(res => res)
    );
  }

  formNumbersContainMultipleSpaces(form: PolicyForm): boolean {
    if (form.InternalFormNumber.trim().indexOf('  ') !== -1) {
      return true;
    }
    if (form.ExternalFormNumber.trim().indexOf('  ') !== -1) {
      return true;
    }
  }


  getProducts(): Observable<any[]> {
    let url = `api/Configs/Products/GetProducts`;
    return this.http.get<any[]>(url).pipe(
      map(res => res));
  }

  getAdminFormsForLOB(lobName: string): Observable<AdminForm[]> {
    let url = `api/AdminForm/Lob/` + lobName;
    return this.http.get<AdminForm[]>(url).pipe(
      map(res => res)
    );
  }
  updateForms(forms: AdminForm[], lob: string): Observable<any> {
    let url = `api/AdminForm/UpdateForms/${lob}`;
    return this.http.post<any>(url, forms).pipe(
      map(res => res));
  }
  saveRollback(forms: AdminForm[]): Observable<any> {
    let url = `api/AdminForm/SaveLatestRollback`;
    return this.http.post<any>(url, forms).pipe(
      map(res => res));
  }
  rollbackLatest(): Observable<any> {
    let url = `api/AdminForm/RollbackLastUpdate`;
    return this.http.get<any>(url).pipe(
      map(res => res));
  }

}
