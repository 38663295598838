<div class="component-container">
  <div class="component-heading-container  d-flex align-items-center justify-content-between">
    <h3 class="component-heading-text">Product Definitions</h3>
    <div class="btn_wrapper" style="margin:10px !important">
      <button type="button" mat-raised-button  class="btn-teal  btnlign" type="button" (click)="editProduct()" *ngIf="this.editVisible">
        Edit Selected Product
      </button>
      <button type="button" mat-raised-button class="btn-teal" type="button" (click)="newProduct()" >
        Create New Product
      </button>
    </div>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12 p-0">
          <div
            class="heading-container fast_admin"
            id="policy-form-heading-container" style="width:35% !important"
          >
            <label class="label_heading">Product:</label>
            <div class="selectdiv select_wrapinline">
              <label>
                <ng-select id="lineOfBusiness" class="custom-select" [items]="lineOfBusinessList" bindLabel="Name" bindValue="Name" (change)="onLOBChange($event)" [(ngModel)]="selectedLOB">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.Name}}">{{item.Name}}</div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
          </div> 
        </div>
        <div id="policy-form-content-container" class="col-md-12 mrgbtm10">
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
          style="
        height: calc(100vh - 206px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      
      "
                           [gridOptions]="formsGridOptions">
          </ag-grid-angular>
        </div>
      </div>
    </div>

  </div>
</div>
