import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ColDef, SelectionChangedEvent, RowSelectedEvent } from 'ag-grid-community';
import { GridOptions } from "ag-grid-community";
import { ImportQueueService } from "../../services/import-queue.service";
import { DetailTriggerComponent } from "../detail-trigger/detail-trigger.component";
import { JobIdCellComponent } from "../job-id-cell/job-id-cell.component";
import { FilterData } from "../../models/filter-data";
import { TransactionSummary } from "../../models/transaction-summary";
import { UpdateContext } from "../../models/update-context";
import { PartnerHealthFilter } from '../../models/partner-health-filter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-master-view',
  templateUrl: './master-view.component.html',
  styleUrls: ['./master-view.component.scss']
})
export class MasterViewComponent implements OnInit {

  staticTabCount: number = 6;
  activeTab: number = 2;
  transactionTabIndex: number = 2;
  detailTabs: string[] = [];

  isFetchingData: boolean = false;
  showFilterError: boolean = false;
  showTableError: boolean = false;
  errorStatuses: string[] = [];
  initialEndDate: any = new Date();
  initialStartDate: any = new Date(this.initialEndDate.getFullYear(), this.initialEndDate.getMonth(), (this.initialEndDate.getDate() - 14), 0, 0, 0, 0);

  private gridApi: any;
  gridOptions: any;
  frameworkComponents: any;
  columnDefs: ColDef[];
  pagination: boolean = true;
  paginationAutoPageSize: boolean = true;
  idBundle: any = {};
  rowSelection: string = "multiple";
  rowMultiSelectWithClick: boolean = true;
  selectedCount: number = 0;
 
  subs: Subscription[] = [];

  @ViewChild('filterOptions') filterOptions: any;
  @ViewChild("updateModal") updateModal: any;

  constructor(private queueService: ImportQueueService ) {
    /*
      Used to identify this component as the parent of the data grid, allowing cell renderers to reference this
      component and call methods on it
    */
    let _this = this;
    this.gridOptions = <GridOptions>{
      context: { componentParent: this },
      getRowStyle: function (params) {
        let backgroundColor = 'transparent';
        if (_this.queueService.getProblemStatuses().indexOf(params.api.getValue("status", params.node)) > - 1) {
          let rawDate = params.api.getValue("processedOn", params.node);          
          let daysPast = _this.calcDaysPast(rawDate);

          if (daysPast > 29) {
            backgroundColor = '#f8d7da';
          } else if (daysPast > 19) {
            backgroundColor = '#fff3cd';
          }
        }
        return { background: backgroundColor };
      }
    }

    this.columnDefs = [
      {
        field: 'productTypeId',
        headerName: "Policy/Claim Number",
        sortable: true,
        filter: true,
        resizable: true,
        cellRenderer: 'detailTriggerRenderer',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      { field: 'status', headerName: "Status", sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'partner', headerName: "Partner", sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'productType', headerName: "Type", sortable: true, filter: true, resizable: true, width: 100 },
      {
        field: 'processedOn',
        headerName: "Processed",
        sortable: true,
        filter: true,
        resizable: true,
        cellRenderer: params => {
          return this.queueService.formatTimestamp(params.value);
        }
      },
      {
        field: 'jobId',
        headerName: "Job ID",
        sortable: true,
        filter: true,
        resizable: true,
        width: 350,
        cellRenderer: 'jobIdRenderer'
      },
      { field: 'transactionId', headerName: "Transaction ID", sortable: true, filter: true, resizable: true, width: 350 },

    ];

    // Assigns the cell renderers to the matching components
    this.frameworkComponents = {
      detailTriggerRenderer: DetailTriggerComponent,
      jobIdRenderer: JobIdCellComponent
    };

  }

  rowData: TransactionSummary[] = [];

  calcDaysPast(dateValue: any): number {
    let daysPast = 0;

    if (!dateValue || isNaN(Date.parse(dateValue))) {
      return daysPast;
    }

    //Remove any Z at the end of the date string so it won't be converted to local time.
    let staticDate = dateValue.replace(/Z$/, "");  
    let pastDate = new Date(Date.parse(staticDate)).getTime();
    let currentDate = new Date().getTime();

    daysPast = (currentDate - pastDate) / (3600000 * 24);
    return daysPast;
  }

  ngOnInit(): void {

    this.errorStatuses = this.queueService.getProblemStatuses();
    this.startListeners();
    // We do not want to wait for the filter options to be populated before requesting transaction data
    
    if (this.queueService.isInitialQueueLoad()) {
      let initialFilter = new FilterData({
        startDate: this.initialStartDate,
        endDate: this.initialEndDate,
        status: this.errorStatuses
      });      
      //Introduce slight delay so health meter API call gets to act first
      let _this = this;
      setTimeout(() => {
        _this.getTransactionData(initialFilter);
      }, 10);
       
    } else {
      this.getTransactionData(new FilterData());
    }
  }

  startListeners(): void {
    let healthSub = this.queueService.partnerHealthFilterListener().subscribe((partnerHealthFilter: PartnerHealthFilter) => {
      if (partnerHealthFilter.active) {
        this.changeActiveTab(this.transactionTabIndex);
      }
    });
    this.subs.push(healthSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  changeActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }

  addTab(idBundle: any) {
    this.detailTabs.push(idBundle);
    this.activeTab = (this.detailTabs.length + this.staticTabCount) - 1
  }

  closeTab(idx: number) {
    event.preventDefault();
    this.detailTabs.splice(idx, 1);
    if (!this.detailTabs.length) {
      this.activeTab = 0;
    }
  }

  matchesTabContext(context: string, tabContext: string) {
    return context == tabContext;
  }

  getTransactionData(filterData: FilterData) {
    this.selectedCount = 0; // Reset any selections
    this.isFetchingData = true;
    this.showTableError = false;
    this.toggleGridLoadStatus(true);
    this.queueService.getTransactionData(filterData)     
      .subscribe(
        // Handle successful retrieval of the transaction records
        (response: any) => {
          this.isFetchingData = false;
          this.rowData = response.body.records;
          this.toggleGridLoadStatus(false);
        },
        // Handle an error response
        error => {
          this.showTableError = true;
          this.isFetchingData = false;
          this.toggleGridLoadStatus(false);
        });
  }

  onGridReady(params: any): void {   
    this.gridApi = params.api;
    if (this.isFetchingData) {
      this.toggleGridLoadStatus(true);
    }
  }

  toggleGridLoadStatus(loading: boolean): void {
    if (this.gridApi) {
      loading ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }

  toggleFilterErrorState(filterError: boolean): void {
    this.showFilterError = filterError;   
  }

  retryFilterOptions(): void {
    event.preventDefault();
    this.filterOptions.reInit();
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    let sCount = event.api.getSelectedNodes().length;
    this.selectedCount = sCount ? sCount: 0 ;
  }

  openUpdateModal(settings: any) {
    let updateContext: UpdateContext = {
      context: settings.context,
      action: settings.action,
      transactions: [],
      jobs: [],
      stagingFiles: []
    };
    let selections: any = this.gridApi.getSelectedNodes();
    selections.forEach((node: any) => {
      updateContext.transactions.push({
        productType: node.data.productType,
        productTypeId: node.data.productTypeId,
        status: node.data.status,
        transactionId: node.data.transactionId
      });
    });
    this.updateModal.openModal(updateContext);
  }

  refreshFromCache() {
    this.toggleGridLoadStatus(true);
    this.rowData = this.queueService.returnCachedTransactionRecords();
    this.gridApi.refreshCells();
    this.toggleGridLoadStatus(false);
  }

}
